<template>
  <router-view />
</template>

<style lang="scss">
body {
  margin: 0;
}

.btn-verde {
  background-color: #4caf50 !important;
  color: #fff !important;
  font-weight: bold !important;

  &:hover {
    background-color: #0f4b28 !important;
    color: #fff !important;
  }
}

.color-verde {
  color: #46b975 !important;
  font-weight: bold !important;
}

.contenedor-productos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 15px;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  &.productos-mosaico {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 0 10px;

    @media (max-width: 968px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 10px;
    }
  }
}
</style>
