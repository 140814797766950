<template>
  <Layout>
    <div class="container mt-5">
      <h1 class="text-center mb-5">
        Tu orden será procesada en unos momentos 🥰🥰
      </h1>
      <h4 class="text-center">
        Tu numero de orden es:
        <strong id="pedido">{{ pedido.id }}</strong>
      </h4>

      <router-link :to="{ name: 'Home' }">
        <span class="text-center d-block font-weight-bold mt-5">
          Realizar otra orden
        </span>
      </router-link>
    </div>
  </Layout>
</template>

<script>
import { computed, onBeforeMount, onUnmounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Layout from "@/layout/Layout.vue";

export default {
  components: {
    Layout,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const pedido = computed(() => store.state.carrito.orden);
    const timer = setTimeout(() => router.push({ name: "Ordenes" }), 5000);

    onBeforeMount(() => {
      //Si no hay ningun pedido, ir a pagina de inicio
      if (!pedido.value) {
        router.push({ name: "Home" });
      } else {
        timer;
      }
    });

    onUnmounted(() => {
      clearTimeout(timer);
    });

    return {
      pedido,
    };
  },
};
</script>

<style scoped>
#pedido {
  color: #28a745;
  font-weight: bold;
}
</style>