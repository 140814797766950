<template>
  <div class="contenedor-suplementos">
    <h4 class="mb-4">{{traducciones.find((item)=>item.item == 'Suplementos Adicionales').value}}</h4>

    <div class="suplementos-contenido">
      <div
        v-for="(suplemento, index) in suplementos"
        :key="suplemento.id"
        :suplemento="suplemento"
        class="suplemento"
      >
        <div class="d-flex flex-column">
          <div class="suplemento-imagen">
            <figure class="mb-2">
              <img v-if="suplemento.logo !== 'null' && suplemento.logo !== null "
                :alt="suplemento.nombre"
                class="img-fluid suplemento-imagen-contenido"
                :src="`https://${restaurante.path}/uploads/images/suplementos/${restaurante.eid}/${restaurante.id}/${suplemento.logo}`"
              />
              <img v-else
                :alt="suplemento.nombre"
                class="img-fluid suplemento-imagen-contenido"
                :src="`https://${restaurante.path}/uploads/images/productos/no-imagen-producto.jpg`"
              />
            </figure>
          </div>
          <div class="suplemento-descripcion">
            <h3 class="suplemento-titulo">
              {{ suplemento.nombre }}
            </h3>
            <h3 class="suplemento-descripcion" style="font-size: 14px">
              {{ suplemento.descripcion }}
            </h3>
            <h3 class="suplemento-precio font-weight-bold">
              + {{ suplemento.precio }}€
            </h3>
            <h5 class="suplemento-impuestos">({{traducciones.find((item)=>item.item == 'ImpuestosIncluidos').value}})</h5>
          </div>
          <div class="cantidad">
            <div class="d-flex flex-column">
              <div class="sp suplemento-caja_botones">
                <div class="suplemento-botones">
                  <div class="sp">
                    <div
                      @click="disminuirSuplemento(suplemento.id)"
                      class="icon-opciones"
                    >
                      <i class="bi bi-dash-circle"></i>
                    </div>
                  </div>
                  <div class="text-center cantidad-suplemento">
                    {{ suplemento.cantidad }}
                  </div>
                  <div class="sp">
                    <div
                      class="icon-opciones"
                      @click="agregarSuplemento(suplemento)"
                    >
                      <i class="bi bi-plus-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, inject } from "@vue/runtime-core";
export default {
  name: "Suplementos",
  props: ["suplementos"],

  setup() {
    const store = useStore();
    const restaurante = computed(() => store.state.restaurante.restaurante);
    const agregarSuplemento = (suplemento) => {
      store.commit("productos/agregarSuplementoExtra", suplemento);
      store.dispatch("productos/setTotal");
    };

    const disminuirSuplemento = (id) => {
      store.commit("productos/disminuirSuplemento", id);
      store.dispatch("productos/setTotal");
    };
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale)    
    return { agregarSuplemento, disminuirSuplemento,restaurante,traducciones };
  },
};
</script>

<style lang="scss" scoped>
.contenedor-suplementos {
  margin: 25px 0;

  .suplementos-contenido {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
  }
}

.suplemento {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);

  &:nth-child(even) {
    background-color: #f9fafb;
  }

  &:nth-child(odd) {
    background-color: #f9fafb;
  }

  .row {
    align-items: center;
  }

  .btn {
    padding: 0 5px;
  }

  .descripcion {
    margin-bottom: 10px;
  }

  .cantidad {
    padding: 0;
  }

  @media (max-width: 698px) {
    // padding: 15px 5px;

    .btn {
      padding: 3px 5px;
      font-size: 8px;
    }

    .imagen {
      padding-right: 0 !important;
    }
  }

  h5 {
    font-size: 12px;
    margin: 0;
    color: #a4a4a4;
    @media (max-width: 698px) {
      font-size: 8px;
    }
  }

  h1 {
    margin: 0;
  }

  h4 {
    margin: 5px 0;
    @media (max-width: 698px) {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 18px;
    @media (max-width: 698px) {
      font-size: 13px;
      margin: 0 0 2px;
      padding: 0 0 5px;
    }
  }

  .suplemento-imagen-contenido {
    max-width: 100%;
    width: 100%;
    height: 120px;
    object-fit: cover;
  }

  .suplemento-descripcion {
    h3 {
      margin: 0;
      padding: 0;
    }

    .suplemento-titulo {
      margin-bottom: 3px;
    }

    .suplemento-precio {
      margin-bottom: 3px;
    }
    margin-bottom: 6px;
  }

  .suplemento-botones {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 0;
    .icon-opciones {
      font-size: 22px;
      cursor: pointer;

      &:hover {
        color: #46b975;
      }
    }
  }
}

.tamanos {
  margin-top: 12px;

  .btn {
    background: rgba(195, 195, 195, 0.251475);
    margin-bottom: 8px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &.seleccionado {
      background: #46b975;
      color: #fff;
      font-weight: 600;
    }
  }
}

.ordenar {
  .btn {
    width: 100%;
  }
}

.cantidad-suplemento {
  font-size: 22px;
  font-weight: bold;
}
</style>
