export const filtros = {
    namespaced: true,
    state: {
      vista: null,
      tamanos: null,
      opciones: null,
      precio: null
    },
    actions: {
      
    },
    mutations: {
      filtroVista(state, payload) {
          state.vista = payload
      },
      filtroTamano(state, payload) {
          state.tamanos = payload
      },
      filtrOpciones(state, payload) {
          state.opciones = payload
      },
      filtroPrecio(state, payload) {
          state.precio = payload
      },
    },
  };
  