<template>
  <Loading v-if="loading" />
  <Error v-if="error" />

  <template v-if="ready">
    <Menu />
    <Notificaciones />
    <Busqueda @filtra="handleCustomChange"/>
    <CategoriasNavs />
    <Carrito />
    <Camarero v-if="muestraCamarero" />
    <Resena v-if="muestraResena" />
    <Sidebar/>

    <slot />

    <MenuFooter />
    
  </template>
  
</template>

<script>
import Menu from "@/components/menu/Menu.vue";
import MenuFooter from "@/components/menu/FooterMenu.vue";
import Carrito from "@/components/carrito/Carrito.vue";
import Busqueda from "@/components/buscador/Buscador.vue";
import CategoriasNavs from "@/components/categoriasNav/CategoriasNav";
import Camarero from "@/components/camarero/Camarero";
import Sidebar from "@/components/sidebar/Sidebar";
import Resena from "@/components/resena/Resena.vue";
import Notificaciones from "@/components/notificaciones/Notificaciones.vue";
import Loading from "@/components/loading/Loading.vue";
import Error from "@/components/error/Error.vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted } from "@vue/runtime-core";

export default {
  components: {
    Menu,
    MenuFooter,
    Carrito,
    Resena,
    CategoriasNavs,
    Busqueda,
    Camarero,
    Sidebar,
    Notificaciones,
    Loading,
    Error,
  },
   methods: {
    forceRerender() {
      this.sidebarKey += 1;
    },
           handleCustomChange(data) {
            this.$emit('filtra', data);
            }
        }, 
        emits: ["filtra"],
  setup() {
    const store = useStore();
    const route = useRoute();
    const params = route.params;
    const loading = computed(() => store.state.loading);
    const ready = computed(() => store.state.ready);
    const error = computed(() => store.state.error.error);
    const muestraCamarero = computed(() => store.state.menu.llamarCamarero);
    const muestraResena = computed(() => store.state.menu.resena);
    const searchQuery = computed(() =>Busqueda.searchQuery);
    // onMounted(async () => {
    //   const response = await getProducts(20);
    //   products.value = response;
    // });
    onMounted(() => {
      store.dispatch("cargandoInformacion", params);
    });
    return { loading, ready, error, muestraCamarero, muestraResena};
  },
   
};

</script>

<style>
</style>
