<template>
  <div class="collapse" id="filtro-menu">
    <div class="contenido-filtro">
      <p class="titulo">Precio</p>
      <ul>
        <li>
          <input
            @input="obtenerPrecio"
            v-model="precio"
            type="radio"
            value="1"
            id="precio_1"
            name="precios"
          />
          <label for="precio_1" class="opcion">€</label>
        </li>
        <li>
          <input
            @input="obtenerPrecio"
            v-model="precio"
            type="radio"
            value="2"
            id="precio_2"
            name="precios"
          />
          <label for="precio_2" class="opcion">€€</label>
        </li>
        <li>
          <input
            @input="obtenerPrecio"
            v-model="precio"
            type="radio"
            value="3"
            id="precio_3"
            name="precios"
          />
          <label for="precio_3" class="opcion">€€€</label>
        </li>
        <li>
          <input
            @input="obtenerPrecio"
            v-model="precio"
            type="radio"
            value="4"
            id="precio_4"
            name="precios"
          />
          <label for="precio_4" class="opcion">€€€€</label>
        </li>
      </ul>
    </div>
    <div class="contenido-filtro">
      <p class="titulo">Elegir tipo de vista de los productos</p>
      <ul>
        <li>
          <input
            v-model="vista"
            @input="obtenerVista"
            type="radio"
            id="vista_1"
            value="completa"
            name="vista"
          />
          <label for="vista_1" class="opcion">Completa</label>
        </li>
        <li>
          <input
            v-model="vista"
            @input="obtenerVista"
            type="radio"
            id="vista_2"
            value="mosaico"
            name="vista"
          />
          <label for="vista_2" class="opcion">Mosaico</label>
        </li>
      </ul>
    </div>
    <div class="contenido-filtro">
      <p class="titulo">Tamaño</p>
      <ul>
        <li>
          <input
            @input="obtenerTamano"
            value="pequeño"
            v-model="tamano"
            type="radio"
            id="tamano_1"
            name="tamanos"
          />
          <label for="tamano_1" class="opcion">Pequeño</label>
        </li>
        <li>
          <input
            @input="obtenerTamano"
            value="mediano"
            v-model="tamano"
            type="radio"
            id="tamano_2"
            name="tamanos"
          />
          <label for="tamano_2" class="opcion">Mediano</label>
        </li>
        <li>
          <input
            @input="obtenerTamano"
            value="grande"
            v-model="tamano"
            type="radio"
            id="tamano_3"
            name="tamanos"
          />
          <label for="tamano_3" class="opcion">Grande</label>
        </li>
        <li>
          <input
            @input="obtenerTamano"
            value="extra grande"
            v-model="tamano"
            type="radio"
            id="tamano_4"
            name="tamanos"
          />
          <label for="tamano_4" class="opcion">Extra grande</label>
        </li>
      </ul>
    </div>

    <div class="contenido-filtro">
      <p class="titulo">Filtrar por</p>

      <ul>
        <li>
          <input
            @input="obtenerFiltrar"
            v-model="filtrar"
            value="opinion"
            type="radio"
            id="filtro_1"
            name="filtro"
          />
          <label for="filtro_1" class="opcion">Opiniones</label>
        </li>
        <li>
          <input
            @input="obtenerFiltrar"
            v-model="filtrar"
            value="calidad"
            type="radio"
            id="filtro_2"
            name="filtro"
          />
          <label for="filtro_2" class="opcion">Calidad de foto</label>
        </li>
        <li>
          <input
            @input="obtenerFiltrar"
            v-model="filtrar"
            value="suplementos"
            type="radio"
            id="filtro_3"
            name="filtro"
          />
          <label for="filtro_3" class="opcion">Con suplementos</label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const filtrar = ref("");
    const tamano = ref("");
    const vista = ref("");
    const precio = ref("");

    const obtenerFiltrar = (e) => {
      console.log(e.target.value);
      store.commit("filtros/filtrOpciones", e.target.value);
    };

    const obtenerTamano = (e) => {
      console.log(e.target.value);
      store.commit("filtros/filtroTamano", e.target.value);
    };

    const obtenerVista = (e) => {
      console.log(e.target.value);
      store.commit("filtros/filtroVista", e.target.value);
    };

    const obtenerPrecio = (e) => {
      console.log(e.target.value);
      store.commit("filtros/filtroPrecio", e.target.value);
    };

    return {
      filtrar,
      tamano,
      vista,
      precio,
      obtenerFiltrar,
      obtenerTamano,
      obtenerVista,
      obtenerPrecio,
    };
  },
};
</script>

<style>
</style>
