<template>
  <div class="alergenos">
    <h4 class="mb-3">{{traducciones.find((item)=>item.item == 'Alergenos').value}}</h4>

    <div class="lista-alergenos">
      <div v-for="alergeno in alergenos" :key="alergeno.id" class="alergeno">
        <span class="icono">
          <img
            :src="require(`@/assets/images/alergenos/${alergeno.logo}`)"
            alt=""
          />
        </span>
        <span class="descripcion">{{ alergeno.nombre }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Alergeno",
  props: ["alergenos"],
  setup() {
    const store = useStore();
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale)
  return { traducciones };
  },
};
</script>

<style lang="scss" scoped>
.alergenos {
  margin-bottom: 30px;
}
.lista-alergenos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .alergeno {
    display: inline-flex;
    align-items: center;
    padding: 5px 15px;
    background: #f3f6f9;
    border-radius: 50px;
    margin-bottom: 8px;
    color: #3c505a;
    border: 1px solid #ebeff3;
    width: 49%;

    &:last-child {
      margin-right: 0;
    }

    .icono {
      margin-right: 8px;
      img {
        width: 24px;
        height: 24px;
        display: block;
        box-shadow: none;
      }
    }

    .descripcion {
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>
