import { API_URL } from "../../utils/constants";

export const llamar = {
    namespaced: true,
    state: {
        loading:false,
        error: false,
    },
    actions: {
        async fetchLlamar({ commit }, payload) {
            alert ('a');
            commit("loading", true);
            try {
              let response = await fetch(
                `${API_URL}/llamar?action=CALL&puesto=327`
              );
      
              let prod = await response.json();
      
              commit("fetchLlamar", prod.messages);
              commit("loading", false);
            } catch (err) {
              console.log(err); // TypeError: failed to fetch
              commit("fetchLlamar", null)
              commit("loading", false);
              commit("error", true);
            }
          }
  
    },
    mutations: {
        fetchLlamar(state, payload) {
            state.result = payload;
          },
          loading(state, payload) {
            state.loading = payload;
          },
          error(state, payload) {
            state.error = payload
          }
    },
    getters: {
        OrdenarPorIdioma: (state, _, rootState) => {
          if (state.preguntas) {
            const find = state.preguntas.filter(
              producto => producto.locale === rootState.idiomas.options.locale
            );
            
            return find;
          }
        },
      }
  };
  
