<template>
  <router-view></router-view>
  <Layout>
    <div v-if="!!productos" class="container-xl mobile-m">
      <div class="contenedor-productos"
:class="[modoMosaico === 'mosaico' ? 'productos-mosaico' : '']" 
    >
        <ProductoDestacado
          v-for="producto in productos"
          :key="producto.id"
          :props="producto"
        />
      </div>
    </div>

    <div class="no-disponible text-center" v-else>
      <h3 class="mt-5">
        No has agregado ningun producto a tu lista de deseos 
      </h3>
      <router-link to="/" class="mt-3">Volver al menu</router-link>
    </div>
  </Layout>
</template>

<script>
import { watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onBeforeMount, computed } from "@vue/runtime-core";

import Layout from "@/layout/Layout.vue";
import ProductoDestacado from "@/components/producto/ProductoDestacado.vue";

export default {
  name: "Sugerencias",
  components: {
    ProductoDestacado,
    Layout,
  },

  setup() {
    const route = useRoute();
    const store = useStore();

    onBeforeMount(() => {
      // const id = route.params.id;
      store.dispatch("productos/fetchDestacados");
    });

    const productos = computed(
      () => store.getters["productos/OrdenarPorIdioma"]
    );

    const loading = computed(() => store.state.productos.loading);

    watch(
      () => route.params.id,
      (value, prevValue) => {
        console.log(value, prevValue);
        store.dispatch("productos/fetchDestacados", value);
      }
    );
const modoMosaico = computed(() => store.state.filtros.vista);
    return { modoMosaico, productos, loading };
  },
};
</script>

<style>
</style>
