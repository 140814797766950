import { API_URL } from "../../utils/constants";

export const productos = {
  namespaced: true,
  state: {
    productos: [],
    producto: {},
    loading: false,
    destacados: null,
    listaDeseo: [],
    comentariosProducto : []
  },
  actions: {
    async fetchProductos({ commit, rootState }, payload) {
      commit("loading", true);
      
      try {
        let sucursalId = rootState.restaurante.restaurante.id;

        let response = await fetch(
          `${API_URL}/getProductos?categoria=${payload}&puesto=1&sucursal=${sucursalId}&withalergenos=true`
        );

        let prod = await response.json();
        //console.log(JSON.stringify(prod));
        if (prod.productos)
        var agregarCantidades = prod.productos.map(productos => {
          productos.total = productos.precio;
          if (productos.suplementos)
          productos.suplementos = productos.suplementos.map(x => ({
            ...x,
            cantidad: 0
          })),
          (productos.extras = []), (productos.tamano = []);
          return productos;
        });

        commit("addProductos", agregarCantidades);
        commit("loading", false);
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
        commit("addProductos", []);
        commit("loading", false);
      }
    },
    async fetchComentarios({ commit, rootState,state }, payload) {
      commit("loading", true);

      try {
        

        let response = await fetch(
          `${API_URL}/getComentariosProducto?producto=${payload}`
        );

        let comentarios = await response.json();
        commit("addComentarios", comentarios.comentariosproducto);

        commit("loading", false);
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
        commit("addComentarios", []);
        commit("loading", false);
      }
    },
    async fetchDestacados({ commit, rootState }) {
      commit("loading", true);
      try {
        let sucursalId = rootState.restaurante.restaurante.id;
        let response = await fetch(
          `${API_URL}/getProductos?puesto=1&sucursal=${sucursalId}&tipo=2&withalergenos=true`
        );

        let prod = await response.json();

        let agregarCantidades = prod.productos.map(productos => {
          productos.total = productos.precio;
          productos.suplementos = productos.suplementos.map(x => ({
            ...x,
            cantidad: 0
          })),
          (productos.extras = []), (productos.tamano = [])
          return productos;
        });

        commit("addDestacados", agregarCantidades);
        commit("loading", false);
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
        commit("addDestacados", []);
        commit("loading", false);
      }
    },

    obtenerProducto({ commit, getters }, payload) {
      const getProducto = getters.getProduct(Number(payload));

      commit("addProducto", getProducto);
    },

    agregarAlistaDeDeseos({ commit, getters, state }, payload) {
    
      const buscarProducto = state.listaDeseo.find(item => item.id === payload.id);
      if (buscarProducto == undefined) {
        commit("addListaDeseo", payload);
      }
      else
      if (buscarProducto.id === payload.id) {
        //Elminiar de la lista de deseos
       const eliminar = state.listaDeseo.filter(item => item.id !== payload.id);
       commit("borrarDeListaDeseos", eliminar);
      }
    },

    setTamanoExtra({ commit, dispatch, state }, payload) {
      commit(
        "addTamano",
        (state.producto = { ...state.producto, tamano: [payload] })
      );

      setTimeout(() => {
        dispatch("setTotal");
      }, 100);
    },

    setOpcionesExtras({ commit, dispatch, state }, payload) {
      commit(
        "addOpcion",
        (state.producto = { ...state.producto, extras: payload })
      );

      setTimeout(() => {
        dispatch("setTotal");
      }, 100);
    },

    setTotal({ commit, getters, state }) {
      commit(
        "setTotal",
        (state.producto = { ...state.producto, total: getters.totalExtras })
      );
    }
  },
  getters: {
    getProduct: (state, _, rootState) => id => {
      const getId = state.productos.filter(
        producto => producto.id === Number(id)
      );
      const getLocale = getId.find(
        locales => locales.locale === rootState.idiomas.options.locale
      );
      return getLocale;
    },

    getListaDeseos: state => id => {
 
        var getId = state.listaDeseo.find(item => item.id === id);
      
      return getId;
    },

    obtenerActivo: state => id => {
        
        var validate = state.listaDeseo.some(
        (item) => item.id === id
      );

      return validate
    },

    OrdenarPorIdioma: (state, _, rootState) => {
      if (state.productos) {
        const find = state.productos.filter(
          producto => producto.locale === rootState.idiomas.options.locale
        );

        const activos = find.filter(
          activo => activo.activo === true
        )
        
        return activos;
      }
    },

    totalExtras: state => {
      if (!state.producto) return;
     

      const extras = state.producto.extras;
      const tamanos = state.producto.tamano;
      const suplementos = state.producto.suplementos
      let extrasTotal;
      let tamanoTotal;
      let suplementosTotal;

      if (typeof extras === "object") {
        extrasTotal = Object.values(state.producto.extras).reduce(
          (acc, { precio }) => acc + precio,
          state.producto.precio
        );
      }

      if (typeof tamanos === "object") {
        tamanoTotal = Object.values(state.producto.tamano).reduce(
          (acc, { precio }) => acc + precio,
          0
        );
      }

      if (suplementos) {
        suplementosTotal = suplementos.reduce((t,s) => t + (s.cantidad > 0 ? (s.cantidad * s.precio) : 0), 0)
      }

      return tamanoTotal + extrasTotal + suplementosTotal;
    }
  },
  mutations: {
    addProductos(state, payload) {
      state.productos = payload;
    },
    addComentarios(state, payload) {
      
      state.comentariosProducto = payload;

    },
    addDestacados(state, payload) {
      state.productos = payload;
    },
    addProducto(state, payload) {
      state.producto = payload;
    },
    addListaDeseos(state, payload) {
      state.listaDeseo = payload;
    },
    addTamano(state, payload) {
      state.producto = payload;
    },
    addListaDeseo(state, payload) {
      state.listaDeseo = [...state.listaDeseo, payload];
    },

    borrarDeListaDeseos(state, payload) {
      state.listaDeseo = payload;
    },

    addOpcion(state, payload) {
      state.producto = payload;
    },

    vaciarProducto(state) {
      state.producto = null;
    },
    setTotal(state, payload) {
      state.producto = payload;
    },

    loading(state, payload) {
      state.loading = payload;
    },

    agregarSuplementoExtra(state, payload) {
      let suplemento = payload
      const suplementoExtraItem = state.producto.suplementos.findIndex(item => item.id === suplemento.id)

      //Si ya existe solo sumar cantidades
      if(suplementoExtraItem >= 0) {
        state.producto.suplementos[suplementoExtraItem].cantidad = state.producto.suplementos[suplementoExtraItem].cantidad + 1
      } else {
        let suplemento = {
          ...payload,
          cantidad:1
        }

        state.producto.suplementos = [...state.producto.suplementos, suplemento]
      }
    },

    disminuirSuplemento(state, payload) {
      let suplementoId = payload;

      const suplementoExtraItem = state.producto.suplementos.findIndex(item => item.id === suplementoId)

      if(state.producto.suplementos[suplementoExtraItem].cantidad > 0 ) {
          state.producto.suplementos[suplementoExtraItem].cantidad = state.producto.suplementos[suplementoExtraItem].cantidad - 1
      } 
      
    }
  }
};
