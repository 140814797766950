<template>

  <router-view></router-view>
  <Layout @filtra="handleCustomChange">
    <div v-if="productos" class="container-xl mobile-m">
      <Loading v-if="loading" />

      <div
        v-if="!loading"
        :class="[modoMosaico === 'mosaico' ? 'productos-mosaico' : '']"
        class="contenedor-productos"
      >
        <Producto
          v-for="producto in filterItems(productos)"
          :key="producto.id"
          :props="producto"
        />
      </div>
    </div>
    <div style="display:none" v-else class="no-disponible text-center">
      <h3 class="mt-5">{{traducciones.find((item)=>item.item == 'NoHayProductos').value}}</h3>
      <router-link :to="{ name: 'Home' }" class="mt-3"
        >{{traducciones.find((item)=>item.item == 'VolverMenu').value}}</router-link
      >
    </div>
  </Layout>
</template>

<script>
import { watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
import Producto from "@/components/producto/Producto.vue";
import Loading from "@/components/loading/Loading.vue";
import Layout from "@/layout/Layout.vue";
import Filtros from "@/components/buscador/Filtros.vue";
import Alergenos from "@/components/buscador/Alergenos.vue";

export default {
  name: "Productos",
  components: {
    Producto,
    Layout,
    Loading,
    Alergenos,
    Filtros
  },
  emits: ["filtra"],
  setup() {
   
    const route = useRoute();
    const store = useStore();
    const searchQuery = ref("");
    let searchAlergenos =  ref([]);
    if (localStorage.searchAlergenos)
     searchAlergenos = ref(JSON.parse(localStorage.searchAlergenos));
    onMounted(() => {
      const id = route.params.id;
      store.dispatch("productos/fetchProductos", id);
 
    });
    let Camarero = computed(() =>"");
    if (localStorage.Camarero != undefined)
        Camarero = JSON.parse(localStorage.Camarero);
    else
        Camarero = {id:null,name:'AUTOPEDIDO',Rol:null};
    const productos = computed(
      () => store.getters["productos/OrdenarPorIdioma"]
    );
    
    const loading = computed(() => store.state.productos.loading);
   
    if (store.state.filtros.vista == null && store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'ProductoListado') == undefined && store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'ListadoConAlergenos') == undefined)
        store.commit("filtros/filtroVista", 'mosaico');
    else
        if (store.state.filtros.vista == null)
            store.commit("filtros/filtroVista", 'completa');    
    const modoMosaico = computed(() => store.state.filtros.vista);

    watch(
      () => route.params.id,
      (value, prevValue) => {
        
        store.dispatch("productos/fetchProductos", value);
  
      }
    );
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    return { productos, loading, modoMosaico,traducciones,searchQuery,searchAlergenos,Camarero };
  },
   methods: {
    handleCustomChange(data) {
            if (typeof data === 'string' || data instanceof String)
                this.searchQuery = data;
            else
                this.searchAlergenos=data;
                            
            },
    filterItems: function(prod) {
      var app = this;
      
    
      if (app.searchQuery || app.searchAlergenos)
      return prod.filter(function(item) {
       console.log(Array.from(app.searchAlergenos).length);
       
        let regex = new RegExp('(' + app.searchQuery + ')', 'i');
        if (Array.from(app.searchAlergenos).length>0)
            {
                var encontrado = false;
                var len = Array.from(app.searchAlergenos).length;
                for (var i=0; i < len; i++) {
                    let regex2 = new RegExp('('+Array.from(app.searchAlergenos)[i]+')', 'i');
                    if (encontrado === false && JSON.stringify(Array.from(item.alergenos)).match( regex2) != undefined) 
                        encontrado = true; 
                }
                if (encontrado == false) // si encuentra el alérgeno, NO muestres el producto
                    return String(item.nombre).match(regex);
            }
            
        else
            if (item)
            return String(item.nombre).match(regex);
      });
      else
        return prod;
    }
  },
};
</script>

<style>
</style>
