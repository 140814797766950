<template>
  <div class="producto"
    :class="[modoMosaico === 'mosaico' ? 'producto-mosaico' : '']"
    >
    <div class="imagen">
      <router-link
        class="imagen-producto-thumbnail"
        :to="{
          name: 'destacado-modal',
          path: `producto/${props.id}`,
          params: { product_id: `${props.id}` },
        }"
      >
        <div class="liston-novedad">SUGERENCIA</div>
        <!-- <div class="liston-ofertas">OFERTAS</div> -->
        <img
          v-if="props.logo"
          class=""
          :src="`https://${restaurante.path}/uploads/images/productos/${restaurante.eid}/${restaurante.id}/${props.logo}`"
        />

        <img
          alt="No imagen"
          class="img-responsive ng-scope img-fluid"
          v-else
          src="@/assets/images/no-imagen-producto.jpg"
        />
      </router-link>
    </div>

    <div class="descripcion">
      <h3 class="producto-nombre">{{ props.nombre }}</h3>
      <h5 class="producto-descripcion">{{ props.descripcion }}</h5>

      <!-- if promociones <h4 class="producto-precio {{if promociones}} oferta {{/if}}"> -->
      <h4 class="producto-precio">{{ precio(props) }}  {{ restaurante.moneda }}</h4>

      <!-- {{if promociones}}
      <span class="precio-oferta"> 0.99€ </span>
      {{/if}} -->

      <h5 class="producto-impuesto">(Impuestos ya incluídos)</h5>
    </div>

    <div class="cantidad">
      <div class="d-flex flex-column">
        <!-- {{if activo}} -->

        <!-- 
        <router-link
          :to="`/producto/${producto.id}`"
          data-toggle="modal"
          :data-target="`#Modal-${producto.id}`"
          class="text sp boton_elegir caja_botones elegir text-center"
        > -->
        <!-- <div class="icon-opciones">
            <i class="bi bi-plus-circle"></i>
          </div> -->
        <!-- </router-link> -->

        <!-- {{else}} -->
        <div class="sp caja_botones">
          <div class="sp">
            <div @click="disminuir(props)" class="icon-opciones">
              <i class="bi bi-dash-circle"></i>
            </div>
          </div>
          <div class="text-center cantidad">
            {{ counter }}
          </div>
          <div @click="aumentar(props)" class="sp">
            <div class="icon-opciones">
              <i class="bi bi-plus-circle"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {{ props.cantidad }}
  <!-- {{ productosCart[0]._ref }} -->
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  props: ["props"],
  methods: {
 
   precio: function (value) {
    var suplemento = 0;
    if (value.suplementoextra ==0 && value.AplicarSupMesa)
        suplemento = this.restaurante.suplemento;
    else
        if (value.suplementoextra !== undefined && value.suplementoextra !== null && value.suplementoextra.toString().replace(/,/g, '.')>0)
            suplemento = value.suplementoextra.toString().replace(/,/g, '.');
        else
            suplemento = this.restaurante.suplemento;

   var price = value.total;
   if (/^(\d+|(\.\d+))(\.\d+)?%$/.test(suplemento)) {
        suplemento = suplemento.toString().replace(/,/g, '.');
        suplemento = suplemento.substring(0,suplemento.length-1);
        suplemento = Number(price)*Number(suplemento)/100;
        }
   else
       price= Number(price) + Number(suplemento);
  if (this.restaurante.general[0].redondeo)
     {
     price = Math.round(price*10)/10;
     }   
     if (this.restaurante.moneda !=='XAF' && this.restaurante.moneda !=='')
      return Number(price).toFixed(2)
    else
        return Number(price).toFixed(0)
    
    }
  },
  setup(props) {
    const store = useStore();
    const productosCart = computed(() => store.state.carrito.carrito);
    const restaurante = computed(() => store.state.restaurante.restaurante);

    const findCart = productosCart.value.find(
      (item) => item.id === props.props.id
    );

    let cantidad = 0;

    if (findCart) {
      cantidad = findCart.cantidad;
      console.log(findCart.cantidad);
    } else {
      cantidad = 0;
    }

    const counter = ref(cantidad);

    const aumentar = (producto) => {
      counter.value++;
      if (producto) {
        store.dispatch("carrito/agregarCarrito", producto);
      }
    };

    const disminuir = (producto) => {
      store.commit("carrito/disminuir", producto);
      if (counter.value > 0) {
        counter.value--;
      }
    };
    const modoMosaico = computed(() => store.state.filtros.vista);
    return { modoMosaico, aumentar, disminuir, counter, findCart, restaurante };
  },
};
</script>

<style lang="scss" scoped>
.contenedor-productos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 15px;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  &.productos-mosaico {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 0 10px;

    @media (max-width: 968px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 10px;
    }
  }
}

.producto {
  border-radius: 8px;
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.02),
  //   0 2px 4px -1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);

  display: flex;
  align-items: center;
  align-content: center;

  padding: 15px;
  margin: 6px 0;

  //Cuando el modo mosaico este activo
  &.producto-mosaico {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .imagen-producto-thumbnail {
      img {
        @media (max-width: 968px) {
          height: auto;
        }
      }
    }

    .producto-descripcion,
    .producto-impuesto,
    .producto-precio,
    .cantidad {
      display: none;
    }

    .producto-nombre {
      font-size: 18px;
      @media (max-width: 968px) {
        font-size: 12px;
      }
      @media (max-width: 698px) {
        font-size: 9px;
      }
    }

    .producto-precio,
    .precio {
      font-size: 18px;

      @media (max-width: 968px) {
        font-size: 12px;
      }
      @media (max-width: 698px) {
        font-size: 9px;
      }

      &.oferta {
        text-decoration: line-through;
        display: inline-block;
        opacity: 0.7;
        color: #888;
        font-size: 12px;
      }
    }

    .precio-oferta {
      margin-left: 4px;
      font-size: 12px;
      color: #46b975;
      font-weight: bold;

      &.oferta-modal {
        font-size: 20px;
      }
    }

    .imagen {
      flex: 0 0 0;
    }

    .descripcion {
      flex: 0 0 0;
      padding: 5px 0;
    }

    .cantidad {
      flex: 0 0 0;
    }

    @media (max-width: 698px) {
      // padding: 5px;
    }
  }

  .producto-nombre {
    font-size: 18px;
    @media (max-width: 968px) {
      font-size: 12px;
    }
    @media (max-width: 698px) {
      font-size: 9px;
    }
  }

  .producto-precio {
    font-size: 18px;

    @media (max-width: 968px) {
      font-size: 12px;
    }
    @media (max-width: 698px) {
      font-size: 9px;
    }

    &.oferta {
      text-decoration: line-through;
      display: inline-block;
      opacity: 0.7;
    }
  }

  .precio-oferta {
    margin-left: 4px;
    font-size: 12px;
    color: #46b975;
    font-weight: bold;
  }

  //Estilos media query producto
  @media (max-width: 698px) {
    padding: 8px;

    .btn {
      padding: 0px 2px;
      font-size: 14px;
    }

    .imagen {
      padding-right: 0 !important;
    }
  }

  //estilos descripcion
  .descripcion {
    flex: 0 0 60%;
    padding: 0 6px 0 12px;
  }

  .cantidad {
    flex: 0 0 20%;
  }

  .modal-dialog {
    @media (max-width: 698px) {
      margin: 0;
    }
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }

  &:nth-child(even) {
    background-color: #f9fafb;
  }

  &:nth-child(odd) {
    background-color: #f9fafb;
  }

  .producto-total {
    padding: 16px 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 9px 40px;
  }

  figure {
    padding: 10px;
    transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
    cursor: pointer;
    position: relative;
    width: 100%;

    .liston-destacado {
      position: absolute;
      bottom: 0;
      background: #ffaa00;
      width: 125px;
      font-size: 10px;
      padding: 6px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      letter-spacing: 1px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;

      @media (max-width: 698px) {
        width: 80px;
        font-size: 6px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    @media (max-width: 698px) {
      padding: 0px;
    }

    img {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      object-fit: fill;
      height: 125px;
      width: 125px;

      @media (max-width: 968px) {
        width: 80px;
        height: 80px;
      }
    }
  }

  .imagen-producto-thumbnail {
    flex: 0 0 20%;
    position: relative;
    cursor: pointer;
    display: block;
    img {
      border-radius: 8px;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: 125px;

      @media (max-width: 698px) {
        height: 70px;
      }
    }

    .liston-novedad {
      position: absolute;
      width: 100%;
      bottom: 0;
      background: #ffaa00;
      font-size: 10px;
      padding: 6px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      letter-spacing: 1px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;

      @media (max-width: 698px) {
        font-size: 6px;
        padding: 4px 0;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
    .liston-ofertas {
      position: absolute;
      width: 100%;
      bottom: 0;
      background: #46b975;
      font-size: 10px;
      padding: 6px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      letter-spacing: 1px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;

      @media (max-width: 698px) {
        font-size: 6px;
        padding: 4px 0;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  .imagen {
    img {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);

      // border-radius: 8px;
    }

    .img-descripcion {
      margin-bottom: 25px;
      position: relative;
      border-radius: 8px;
      object-fit: cover;
      display: block;
    }

    .social {
      position: absolute;
      display: flex;
      bottom: 15px;
      right: 15px;
      align-items: center;
      z-index: 100;

      div {
        border-radius: 50px;
        cursor: pointer;
        background-color: #fff;
        width: 32px;
        height: 32px;
        margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.1);
      }

      svg {
        width: 20px;
        height: 20px;
      }

      .favoritos {
        width: 42px;
        height: 42px;
        background-image: url("/assets/img/corazon_degradado.png");
        background-repeat: no-repeat;
        background-position: 3px 4px;
        background-color: transparent;
        box-shadow: none;

        .favorito {
          fill: #fff;
          transform: scale(0) translate(-1.08 -3.58);
          transition: 0.2s ease-in-out;
          transform-origin: center center;
        }

        &.activo {
          animation: zoomOn 0.424s ease-in-out;
          background-image: url("/assets/img/corazon_hover.png");
          .favorito {
            fill: #ff5722;
            transform: scale(1) translate(-1.08 -3.58);
          }
        }

        @keyframes zoomOn {
          0% {
            transform: scale(0);
          }

          50% {
            transform: scale(1.5);
          }

          100% {
            transform: scale(1);
          }
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .cerrar {
      width: 42px;
      height: 42px;
      position: absolute;
      top: 15px;
      right: 15px;
      background: #fff;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.1);
      border-radius: 50px;
      cursor: pointer;
      z-index: 500;

      svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        fill: #283646;
      }

      &:hover {
        text-decoration: none;
      }

      i {
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      }
    }

    .favorito {
      width: 34px;
      height: 34px;
      position: absolute;
      bottom: 25px;
      right: 25px;
      background: #fff;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      border-radius: 50px;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: none c;
        }
      }

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f44336;
        margin-top: 10px;
      }
    }
  }

  .info {
    // align-items: center;

    .info-descripcion {
      width: 75%;
    }

    .info-precio {
      text-align: right;

      .btn {
        font-size: 13px;
        padding: 5px 10px;
      }
    }

    .precio {
      font-size: 22px;
      font-weight: bold;
      color: #0aa931;

      @media (max-width: 698px) {
        font-size: 16px;
      }
    }

    h1 {
      font-size: 22px;
      text-align: left;
      margin-bottom: 8px;

      @media (max-width: 698px) {
        font-size: 16px;
      }
    }

    h2 {
      font-size: 14px;
      margin: 0;
      color: #a4a4a4;
      margin-bottom: 5px;
      font-weight: 400;

      @media (max-width: 698px) {
        font-size: 12px;
      }
    }
  }

  .row {
    align-items: center;
  }

  h5 {
    font-size: 12px;
    margin: 0;
    color: #a4a4a4;
    @media (max-width: 698px) {
      font-size: 9px;
    }
  }

  h1 {
    margin: 0;
  }

  h4 {
    margin: 5px 0;
    @media (max-width: 698px) {
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  h3 {
    font-weight: bold;
    @media (max-width: 698px) {
      font-size: 12px;
      margin: 0 0 2px;
    }
  }

  //   .col-3,
  //   .col-6,
  //   .col-5 {
  //     @media (max-width: 698px) {
  //       padding: 0 5px;
  //     }
  //   }

  .caja_botones {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;

    .cantidad {
      font-size: 22px;
      font-weight: bold;

      @media (max-width: 698px) {
        font-size: 14px;
      }
    }

    .icon-opciones {
      font-size: 24px;
      cursor: pointer;
      text-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
      color: #6f6f6f;
      @media (max-width: 698px) {
        font-size: 16px;
      }

      &:hover {
        color: #46b975;
      }
    }
  }

  .cantidad {
    // padding: 0px 40px;

    // @media (max-width: 698px) {
    //   padding: 0 25px 0 0;
    // }

    .boton_elegir {
      width: 45px;
      margin: 0 auto;
    }
  }

  .impuestos {
    @media (max-width: 698px) {
      font-size: 6px;
      margin-top: 2px;
    }
  }

  .platos {
    .tamanos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .seleccionado {
        background: #46b975;
        color: #fff;
        font-weight: 600;
      }

      div {
        padding: 8px 16px;
        margin-right: 0px;
        font-size: 14px;
        width: 49%;
        background-color: rgba(195, 195, 195, 0.251475);
      }
    }
  }

  .modal-producto {
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%) scale(0);
    width: 200px;
    border-radius: 8px;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);
    transition: 0.3s ease-in-out;
    opacity: 0;

    @media (max-width: 698px) {
      padding: 8px;
    }

    //Estilos Compartir
    &.compartir {
      // display: none;
      text-align: center;
      overflow: hidden;

      p {
        font-size: 12px;
      }

      ul {
        padding: 0;
        list-style: none;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      li {
        padding: 0;
        margin: 0 0 8px 0;
        margin: 0;
        opacity: 0;

        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      img {
        width: 28px;
        height: 28px;
        box-shadow: none;
        border-radius: 35%;
        // @media (max-width: 698px) {

        //   margin-right: 4px;
        // }
      }

      a {
        @media (max-width: 698px) {
          font-size: 12px;
        }
      }

      &.mostrar {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        z-index: 100;
        li {
          animation-name: fadeInUp;
          animation-duration: 0.5s;
          animation-fill-mode: both;

          &:nth-child(1) {
            animation-delay: 0.2s;
          }
          &:nth-child(2) {
            animation-delay: 0.4s;
          }

          &:nth-child(3) {
            animation-delay: 0.6s;
          }
          &:nth-child(4) {
            animation-delay: 0.8s;
          }
        }
      }
    }

    //Estilos puntuar
    &.puntuar {
      // display: none;

      &.mostrar {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        overflow: hidden;
        .rating {
          animation-name: fadeInUp;
          animation-duration: 0.5s;
          animation-fill-mode: both;
          animation-delay: 0.2s;
        }
      }
    }
  }

  .extras {
    h4 {
      font-size: 17px;
      font-weight: bold;
      color: #3a4046;
    }
  }

  .lista-alergenos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .alergeno {
      display: inline-flex;
      align-items: center;
      padding: 5px 15px;
      background: #f3f6f9;
      border-radius: 50px;
      margin-bottom: 8px;
      color: #3c505a;
      border: 1px solid #ebeff3;
      width: 49%;

      &:last-child {
        margin-right: 0;
      }

      .icono {
        margin-right: 8px;
        img {
          width: 24px;
          height: 24px;
          display: block;
          box-shadow: none;
        }
      }

      .descripcion {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .opciones {
    .form-check {
      margin-bottom: 10px;
    }
  }

  .tabs {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    cursor: pointer;
    button {
      padding: 8px 16px;
      background: #f3f6f9;
      border-radius: 24px;

      &.active {
        font-weight: bold;
        background: #a7f3d0;
        color: #4b5563;
      }
    }
  }

  #opciones-extras {
  }

  .comentarios {
    .comentario-estrellas {
      display: inline-block;
      height: 27px;
    }
    .comentario {
      background: #f3f6f9;
      padding: 25px;
      border-radius: 16px;
      margin-bottom: 10px;
      position: relative;
      box-shadow: 0px 14.8222px 37.0556px rgba(0, 0, 0, 0.07);

      .estrella {
        position: absolute;
        top: 25px;
        right: 25px;
        font-weight: bold;
      }

      h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
      }

      p {
        font-size: 14px;
        margin: 0;
      }

      .fecha {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }

    .star {
      position: relative;

      display: inline-block;
      width: 0;
      height: 0;

      margin-left: 0.9em;
      margin-right: 0.9em;
      margin-bottom: 1.2em;

      border-right: 0.3em solid transparent;
      border-bottom: 0.7em solid #fc0;
      border-left: 0.3em solid transparent;

      /* Controlls the size of the stars. */
      font-size: 24px;

      &:before,
      &:after {
        content: "";

        display: block;
        width: 0;
        height: 0;

        position: absolute;
        top: 0.6em;
        left: -1em;

        border-right: 1em solid transparent;
        border-bottom: 0.7em solid #fc0;
        border-left: 1em solid transparent;

        transform: rotate(-35deg);
      }

      &:after {
        transform: rotate(35deg);
      }
    }
  }

  .contenedor-fotos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 20px 0;

    div {
      height: 185px;
      background: #ccc;
      margin: 5px;
    }
  }

  .tabs-opciones {
    margin-bottom: 25px;
  }

  .comentario-form {
    margin-bottom: 25px;

    .btn {
      width: 100%;
      margin-top: 16px;
      padding: 10px 0;
    }

    p {
      font-weight: bold;
      text-align: center;
    }
  }

  .opciones-pill {
    // border-bottom: 1px solid #ccc;
    box-shadow: 0px 14.8222px 37.0556px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    border-radius: 12px;
    margin: 0 auto 30px auto;
    width: 240px;

    #pills-home-tab {
      &.active {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }

    #pills-profile-tab {
      &.active {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    a {
      color: #3c505a;
      opacity: 0.5;
      &.active {
        background: #f3f6f9;
        box-shadow: 0px 14.8222px 37.0556px rgba(0, 0, 0, 0.1);
        color: #3c505a;
        border: 0;
        font-weight: bold;
        opacity: 1;
      }
    }
  }
}

/* Animation */

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.mosaico {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);
  border: 0;
  border-radius: 8px;
  margin: 4px;
  transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  cursor: pointer;

  .producto {
    // Arreglar esto despues
    &:nth-child(even) {
      background-color: transparent;
    }

    &:nth-child(odd) {
      background-color: transparent;
    }

    .img-descripcion {
      margin-bottom: 10px;
    }
  }

  .cerrar {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 25px;
    right: 25px;
    background: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      fill: #283646;
    }

    &:hover {
      text-decoration: none;
    }

    i {
      font-size: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
    }
  }

  figure {
    position: relative;
  }

  img {
    border-bottom: 1px solid #cccccc29;
    // border-top-left-radius: 8px;
    // border-top-right-radius: 8px;
  }

  .imagen {
    figure {
      img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        width: 100%;
        height: 120px;
        object-fit: cover;
      }
    }
  }

  .mosaico-precio {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 8px;
    background: #46b975;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 8px;
    font-size: 11px;
  }

  .liston-novedad {
    padding: 4px 8px;
    background: #17a2b8;
    text-align: center;
    font-weight: bold;
    color: #fff;
    letter-spacing: 1px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 9px;
  }

  h3 {
    font-size: 14px;
    padding: 10px;
    margin: 0;

    @media (max-width: 1024px) {
      font-size: 9px;
    }
  }
}

.contenedor-mosaico {
  margin-top: 10px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t 
      follow these rules. Every browser that supports :checked also supports :not(), so
      it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input {
  position: absolute;
  // left: -9999px;
  visibility: hidden;
  clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
  float: right;
  width: 1em;
  /* padding:0 .1em; */
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 26px;
  /* line-height:1.2; */
  color: #ddd;
}

.rating:not(:checked) > label:before {
  content: "★ ";
}

.rating > input:checked ~ label {
  color: #ffc107;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffc107;
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffc107;
}

.rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.rating-mensaje {
  display: none;
  font-weight: bold;
  color: green;
  text-align: center;
  font-size: 13px;
}

.alertas {
  display: none;
}

.no-imagen {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  object-fit: fill;
  height: 125px;
  width: 125px;

  @media (max-width: 968px) {
    width: 80px;
    height: 80px;
  }
}

.agregar-foto {
  position: absolute;
  z-index: 1;
  width: 100%;
  background: #46b975;
  text-align: left;
  padding: 15px;
  font-weight: bold;
  bottom: 0;
  color: #fff;
  font-size: 12px;
}

#agregar-fotografia {
  margin-bottom: 25px;
  padding: 10px 0;

  button {
    width: 100%;
    padding: 10px 25px;
    font-weight: bold;
  }
}
</style>
