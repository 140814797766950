<template>
  <div class="modal fade" id="llamarIdioma" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="cerrar" data-bs-dismiss="modal">
            <svg
              fill="#545454"
              width="25"
              viewBox="0 0 329.26933 329"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
              ></path>
            </svg>
          </div>
          <h2 class="fw-bold my-3 text-center mb-5">{{traducciones.find((item)=>item.item == 'SeleccioneIdioma').value}}</h2>

          <div class="row">
            <div
              v-for="idioma in idiomas"
              :key="idioma.nombre"
              class="col-lg-4 col-sm-6"
            >
              <div
                class="contenedor-idioma"
                @click="seleccionarIdioma(idioma.locale)"
                data-bs-dismiss="modal"
                :class="currentLocale === idioma.locale ? 'seleccionado' : ''"
              >
                <div>
                  <img
                    :src="require(`@/assets/images/idiomas/${idioma.imagen}`)"
                    alt=""
                  />
                </div>
                <div>{{ idioma.nombre }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import * as bootstrap from "bootstrap";

export default {

  setup() {
    const store = useStore();
    const dialog = inject("$swal");
    const currentLocale = ref(store.state.idiomas.options.locale);
    
    onMounted(() => {
      let myModal = new bootstrap.Modal(
        document.getElementById("llamarIdioma"),
        {
          backdrop: "true",
        }
      );
      myModal.show();

      document
        .getElementById("llamarIdioma")
        .addEventListener("hidden.bs.modal", function (event) {
          backdrop();
        });
    });
    ;
    const idiomas = computed(() => store.state.idiomas.options.idiomas);
    // const currentLangague = computed(() => store.state.options.options.locale);
    var traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    const seleccionarIdioma = (idioma) => {
      store.commit("idiomas/cambiarIdioma", idioma);
      traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
      dialog({
        position: "center",
        icon: "success",
        title: traducciones.find((item)=>item.item == 'Idiomacambiado').value,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        localStorage.ivaincluido = store.state.restaurante.restaurante.general[0].totalivaincluido
            store.commit("menu/traduce", store.state.restaurante.restaurante.traducciones.filter( item => item.locale === idioma));
        });
    };

    const backdrop = () => {
      store.commit("menu/mostrarIdiomas", false);
    };

    return { idiomas, seleccionarIdioma, currentLocale, traducciones };
  },
};
</script>

<style lang="scss" scoped>
.modal-idiomas {
  .swal2-modal {
    width: 960px;
  }

  .cerrar {
    text-align: right;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .swal2-content {
    padding: 0;
  }
}

.contenedor-idioma {
  display: flex;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 15px;
  transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  image-rendering: optimizeSpeed; /*                     */
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: optimize-contrast; /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                */
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 30px 60px;
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }

  div {
    &:first-child {
      margin-right: 25px;
    }
  }
}

.cerrar {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.seleccionado {
  background: #d1fae5;
}
</style>
