<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="llamarCamarero"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="cerrar" data-bs-dismiss="modal">
            <svg
              fill="#545454"
              width="25"
              viewBox="0 0 329.26933 329"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
              ></path>
            </svg>
          </div>
          <div class="row" v-if="!solicitarCuenta" id="camarero-opciones">
            <div class="col-sm-6">
              <div
                @click="solicitarCamarero()"
                data-bs-dismiss="modal"
                class="llamar-container llamar-camarero"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="71"
                    height="71"
                    fill="none"
                    viewBox="0 0 71 71"
                  >
                    <path
                      fill="#4A4F4B"
                      d="M50.028 30.89a18.792 18.792 0 004.331-12.019C54.36 7.424 44.791 0 35.5 0c-9.92 0-18.86 7.874-18.86 18.871 0 4.718 1.628 8.753 4.332 12.02C9.144 32.505 0 42.677 0 54.948v2.146l.69.62A52.014 52.014 0 0033.42 71V52.173h4.16V71a52.015 52.015 0 0032.73-13.286l.69-.62v-2.146c0-12.27-9.144-22.443-20.972-24.057zM14.006 61.8a47.931 47.931 0 01-9.846-6.576v-.276c0-7.346 3.955-13.783 9.846-17.296V61.8zm30.508-10.772L35.5 47.6l-9.014 3.428V39.442L35.5 42.87l9.014-3.428v11.586zM35.5 33.579c-7.551 0-13.79-5.727-14.607-13.069a18.857 18.857 0 008.921 2.246 18.83 18.83 0 0012.465-4.709 18.933 18.933 0 003.652-4.362 18.87 18.87 0 004.24 6.087c-.467 7.692-6.868 13.807-14.671 13.807zm31.34 21.645a47.927 47.927 0 01-9.846 6.576V37.651c5.89 3.513 9.846 9.95 9.846 17.296v.276z"
                    />
                  </svg>
                </div>
                <div class="texto">{{traducciones.find((item)=>item.item == 'LlamarCamarero').value}}</div>
              </div>
            </div>
            <div class="col-sm-6">
              <div
                class="llamar-container solicitar"
                @click="solicitarCuentaHandler()"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="71"
                    height="71"
                    fill="none"
                    viewBox="0 0 71 71"
                  >
                    <path
                      fill="#000000"
                      d="M 0.00,0.00
           C 0.00,0.00 0.00,71.00 0.00,71.00
             0.00,71.00 71.00,71.00 71.00,71.00
             71.00,71.00 71.00,0.00 71.00,0.00
             71.00,0.00 0.00,0.00 0.00,0.00 Z
           M 23.43,5.19
           C 23.43,5.19 23.43,5.24 23.43,5.24
             23.43,5.24 23.38,5.19 23.38,5.19
             23.38,5.19 23.43,5.19 23.43,5.19 Z
           M 28.67,13.16
           C 28.87,13.36 29.14,13.47 29.38,13.61
             29.38,13.61 30.42,14.30 30.42,14.30
             30.42,14.30 31.13,14.82 31.13,14.82
             32.00,15.53 32.33,15.99 33.42,16.40
             33.67,16.49 33.92,16.65 34.19,16.66
             34.19,16.66 34.19,16.77 34.19,16.77
             34.47,16.77 34.62,16.89 34.84,17.04
             35.37,17.40 35.68,17.74 35.87,18.35
             36.28,19.61 35.98,20.97 35.56,22.17
             35.43,22.57 35.15,23.32 34.84,23.59
             34.84,23.59 34.69,22.61 34.69,22.61
             34.49,21.46 34.32,20.58 33.32,19.83
             32.96,19.57 32.51,19.29 32.06,19.33
             31.87,19.20 31.74,19.22 31.51,19.22
             31.51,19.22 29.60,19.54 29.60,19.54
             29.60,19.54 28.18,19.66 28.18,19.66
             28.18,19.66 27.03,19.72 27.03,19.72
             27.03,19.72 26.27,19.66 26.27,19.66
             25.75,19.66 24.54,19.52 24.03,19.41
             23.43,19.27 23.13,19.15 22.50,19.30
             22.33,19.34 22.08,19.37 22.01,19.55
             21.50,19.55 21.16,20.09 20.75,20.32
             20.75,20.32 20.81,20.48 20.81,20.48
             20.19,21.03 20.12,21.84 20.03,22.61
             20.03,22.61 19.72,25.40 19.72,25.40
             19.44,25.12 19.21,24.26 19.08,23.87
             18.82,23.08 18.52,21.90 18.51,21.08
             18.51,21.08 18.51,20.04 18.51,20.04
             18.52,19.69 18.57,19.35 18.68,19.01
             18.68,19.01 18.57,19.06 18.57,19.06
             19.07,17.78 19.56,16.97 20.70,16.15
             20.70,16.15 21.93,15.45 21.93,15.45
             21.93,15.45 22.88,14.13 22.88,14.13
             23.65,13.25 24.63,12.62 25.83,12.62
             25.83,12.62 26.43,12.62 26.43,12.62
             26.88,12.62 27.43,12.75 27.85,12.89
             28.11,12.98 28.41,13.17 28.67,13.16 Z
           M 23.43,13.44
           C 23.43,13.44 23.43,13.49 23.43,13.49
             23.43,13.49 23.38,13.44 23.38,13.44
             23.38,13.44 23.43,13.44 23.43,13.44 Z
           M 30.26,13.98
           C 30.26,13.98 30.26,14.04 30.26,14.04
             30.26,14.04 30.20,13.98 30.20,13.98
             30.20,13.98 30.26,13.98 30.26,13.98 Z
           M 30.26,14.09
           C 30.26,14.09 30.26,14.15 30.26,14.15
             30.26,14.15 30.20,14.09 30.20,14.09
             30.20,14.09 30.26,14.09 30.26,14.09 Z
           M 21.08,15.84
           C 21.08,15.84 21.08,15.89 21.08,15.89
             21.08,15.89 21.03,15.84 21.03,15.84
             21.03,15.84 21.08,15.84 21.08,15.84 Z
           M 20.48,16.28
           C 20.48,16.28 20.48,16.33 20.48,16.33
             20.48,16.33 20.43,16.28 20.43,16.28
             20.43,16.28 20.48,16.28 20.48,16.28 Z
           M 20.37,16.33
           C 20.37,16.33 20.37,16.38 20.37,16.38
             20.37,16.38 20.32,16.33 20.32,16.33
             20.32,16.33 20.37,16.33 20.37,16.33 Z
           M 35.39,17.37
           C 35.39,17.37 35.39,17.42 35.39,17.42
             35.39,17.42 35.34,17.37 35.34,17.37
             35.34,17.37 35.39,17.37 35.39,17.37 Z
           M 35.50,17.48
           C 35.50,17.48 35.50,17.53 35.50,17.53
             35.50,17.53 35.45,17.48 35.45,17.48
             35.45,17.48 35.50,17.48 35.50,17.48 Z
           M 35.72,17.80
           C 35.72,17.80 35.72,17.86 35.72,17.86
             35.72,17.86 35.66,17.80 35.66,17.80
             35.66,17.80 35.72,17.80 35.72,17.80 Z
           M 32.71,20.43
           C 32.83,20.68 33.09,20.91 33.32,21.08
             33.32,21.08 33.59,22.01 33.59,22.01
             33.59,22.01 33.89,23.65 33.89,23.65
             33.97,24.06 34.13,24.72 34.13,25.12
             34.13,25.12 34.13,29.60 34.13,29.60
             34.13,31.28 33.50,32.59 32.45,33.86
             31.71,34.76 31.53,35.09 30.48,35.74
             28.68,36.86 26.20,36.87 24.36,35.85
             23.33,35.28 22.80,34.68 22.10,33.75
             22.10,33.75 21.44,32.88 21.44,32.88
             21.03,32.25 20.72,31.38 20.58,30.64
             20.43,29.88 20.48,28.36 20.48,27.53
             20.48,27.53 20.85,24.09 20.85,24.09
             20.92,23.44 21.03,21.94 21.25,21.41
             21.53,20.73 22.36,20.17 23.10,20.21
             23.10,20.21 24.41,20.46 24.41,20.46
             24.41,20.46 26.00,20.64 26.00,20.64
             26.00,20.64 26.49,20.64 26.49,20.64
             26.49,20.64 27.31,20.70 27.31,20.70
             27.31,20.70 28.13,20.64 28.13,20.64
             28.91,20.64 29.22,20.59 29.98,20.46
             29.98,20.46 31.62,20.16 31.62,20.16
             32.01,20.15 32.34,20.41 32.71,20.43 Z
           M 32.77,20.37
           C 32.77,20.37 32.77,20.43 32.77,20.43
             32.77,20.43 32.71,20.37 32.71,20.37
             32.71,20.37 32.77,20.37 32.77,20.37 Z
           M 21.79,20.59
           C 21.79,20.59 21.79,20.64 21.79,20.64
             21.79,20.64 21.74,20.59 21.74,20.59
             21.74,20.59 21.79,20.59 21.79,20.59 Z
           M 21.74,20.70
           C 21.74,20.70 21.74,20.75 21.74,20.75
             21.74,20.75 21.68,20.70 21.68,20.70
             21.68,20.70 21.74,20.70 21.74,20.70 Z
           M 21.63,20.81
           C 21.63,20.81 21.63,20.86 21.63,20.86
             21.63,20.86 21.57,20.81 21.57,20.81
             21.57,20.81 21.63,20.81 21.63,20.81 Z
           M 33.15,20.81
           C 33.15,20.81 33.15,20.86 33.15,20.86
             33.15,20.86 33.10,20.81 33.10,20.81
             33.10,20.81 33.15,20.81 33.15,20.81 Z
           M 35.61,22.28
           C 35.61,22.28 35.61,22.34 35.61,22.34
             35.61,22.34 35.55,22.28 35.55,22.28
             35.55,22.28 35.61,22.28 35.61,22.28 Z
           M 19.12,24.19
           C 19.12,24.19 19.12,24.25 19.12,24.25
             19.12,24.25 19.06,24.19 19.06,24.19
             19.06,24.19 19.12,24.19 19.12,24.19 Z
           M 43.04,31.08
           C 43.04,31.08 43.04,31.13 43.04,31.13
             43.04,31.13 42.98,31.08 42.98,31.08
             42.98,31.08 43.04,31.08 43.04,31.08 Z
           M 42.98,31.29
           C 42.98,31.29 43.20,31.51 43.20,31.51
             43.28,31.91 43.46,32.05 43.65,32.39
             43.65,32.39 44.97,34.52 44.97,34.52
             44.97,34.52 48.09,39.54 48.09,39.54
             48.09,39.54 48.99,40.96 48.99,40.96
             49.17,41.26 49.32,41.60 49.59,41.84
             49.59,41.84 50.06,42.71 50.06,42.71
             50.06,42.71 51.12,44.40 51.12,44.40
             50.82,44.33 50.45,44.01 50.19,43.82
             49.92,43.62 48.96,42.96 48.81,42.76
             48.70,42.63 48.62,42.43 48.55,42.27
             48.55,42.27 48.33,42.38 48.33,42.38
             48.31,42.26 48.27,42.21 48.39,42.16
             48.15,41.62 47.65,40.93 47.33,40.42
             47.33,40.42 45.41,37.36 45.41,37.36
             45.41,37.36 44.62,36.10 44.62,36.10
             44.50,35.90 44.34,35.62 44.13,35.51
             43.86,35.36 43.18,35.68 42.76,35.66
             42.27,35.63 41.73,35.45 41.35,35.14
             41.12,34.95 40.86,34.48 40.52,34.56
             40.33,34.60 39.49,35.15 39.26,35.29
             39.26,35.29 37.52,36.36 37.52,36.36
             37.52,36.36 37.13,36.65 37.13,36.65
             36.88,36.82 36.76,36.80 36.48,37.08
             36.48,37.08 36.37,37.03 36.37,37.03
             36.21,37.27 35.91,37.29 35.86,37.58
             35.82,37.76 36.03,38.13 36.09,38.34
             36.21,38.79 36.18,39.33 36.01,39.76
             35.88,40.07 35.80,40.22 35.55,40.47
             35.40,40.62 35.13,40.82 35.16,41.07
             35.17,41.19 35.28,41.35 35.34,41.45
             35.51,41.73 35.71,42.10 35.94,42.33
             35.94,42.33 36.16,42.65 36.16,42.65
             36.16,42.65 36.40,43.15 36.40,43.15
             36.40,43.15 37.08,44.13 37.08,44.13
             37.11,44.33 37.22,44.43 37.36,44.57
             37.40,44.83 37.70,45.21 37.85,45.44
             37.85,45.44 38.98,47.24 38.98,47.24
             38.98,47.24 40.25,49.26 40.25,49.26
             40.25,49.26 40.70,49.92 40.70,49.92
             40.70,49.92 42.43,52.70 42.43,52.70
             42.43,52.70 43.50,54.40 43.50,54.40
             43.64,54.63 43.85,55.04 44.08,55.18
             44.33,55.33 44.54,55.17 44.78,55.09
             45.12,55.00 45.48,54.98 45.82,55.04
             46.19,55.11 46.61,55.27 46.89,55.52
             47.09,55.70 47.40,56.12 47.68,56.12
             47.91,56.12 48.64,55.59 48.88,55.45
             49.37,55.15 49.29,55.28 49.59,55.15
             49.59,55.15 50.41,54.67 50.41,54.67
             50.74,54.46 50.73,54.31 51.23,54.00
             51.47,53.85 52.22,53.45 52.34,53.23
             52.47,52.99 52.29,52.76 52.26,52.54
             52.21,52.38 52.24,52.26 52.26,52.10
             52.26,52.10 54.18,53.33 54.18,53.33
             54.18,53.33 54.83,53.75 54.83,53.75
             54.83,53.75 55.27,53.85 55.27,53.85
             55.27,53.85 55.18,54.03 55.18,54.03
             55.18,54.03 54.18,54.62 54.18,54.62
             54.18,54.62 53.85,54.73 53.85,54.73
             53.85,54.73 53.41,55.12 53.41,55.12
             53.41,55.12 51.50,56.32 51.50,56.32
             51.50,56.32 50.30,57.02 50.30,57.02
             50.30,57.02 50.36,57.07 50.36,57.07
             50.36,57.07 50.30,57.18 50.30,57.18
             49.97,57.21 49.70,57.44 49.43,57.61
             49.03,57.86 47.76,58.61 47.52,58.88
             47.26,58.89 47.07,59.08 46.86,59.22
             46.86,59.22 45.49,60.02 45.49,60.02
             45.49,60.02 44.86,58.98 44.86,58.98
             44.86,58.98 43.36,56.58 43.36,56.58
             43.36,56.58 38.39,48.61 38.39,48.61
             38.39,48.61 35.91,44.62 35.91,44.62
             35.91,44.62 35.03,43.20 35.03,43.20
             35.03,43.20 34.52,42.49 34.52,42.49
             34.48,42.22 33.98,41.51 33.81,41.23
             33.81,41.23 32.71,39.49 32.71,39.49
             32.53,39.18 32.14,38.51 31.90,38.29
             32.07,37.99 32.32,37.87 32.61,37.70
             32.61,37.70 33.81,36.97 33.81,36.97
             33.81,36.97 37.74,34.51 37.74,34.51
             38.17,34.24 39.37,33.59 39.65,33.26
             39.65,33.26 40.58,32.75 40.58,32.75
             40.58,32.75 42.11,31.81 42.11,31.81
             42.11,31.81 42.98,31.29 42.98,31.29 Z
           M 33.59,32.22
           C 33.59,32.22 33.59,32.28 33.59,32.28
             33.59,32.28 33.53,32.22 33.53,32.22
             33.53,32.22 33.59,32.22 33.59,32.22 Z
           M 48.12,32.33
           C 48.12,32.33 48.57,34.30 48.57,34.30
             48.57,34.30 48.75,35.17 48.75,35.17
             48.75,35.17 49.51,38.39 49.51,38.39
             49.51,38.39 50.03,40.80 50.03,40.80
             50.03,40.80 49.27,39.65 49.27,39.65
             49.27,39.65 47.87,37.41 47.87,37.41
             47.87,37.41 47.30,36.59 47.30,36.59
             47.30,36.59 46.75,35.61 46.75,35.61
             46.75,35.61 45.66,33.86 45.66,33.86
             45.66,33.86 45.11,33.10 45.11,33.10
             45.11,33.10 45.99,32.82 45.99,32.82
             45.99,32.82 47.30,32.53 47.30,32.53
             47.30,32.53 48.12,32.33 48.12,32.33 Z
           M 39.87,32.88
           C 39.87,32.88 39.87,32.93 39.87,32.93
             39.87,32.93 39.81,32.88 39.81,32.88
             39.81,32.88 39.87,32.88 39.87,32.88 Z
           M 21.57,33.04
           C 21.57,33.04 21.57,33.10 21.57,33.10
             21.57,33.10 21.52,33.04 21.52,33.04
             21.52,33.04 21.57,33.04 21.57,33.04 Z
           M 32.93,33.37
           C 32.93,33.37 32.93,33.42 32.93,33.42
             32.93,33.42 32.88,33.37 32.88,33.37
             32.88,33.37 32.93,33.37 32.93,33.37 Z
           M 32.44,33.92
           C 32.44,33.92 32.44,33.97 32.44,33.97
             32.44,33.97 32.39,33.92 32.39,33.92
             32.39,33.92 32.44,33.92 32.44,33.92 Z
           M 38.56,33.97
           C 38.56,33.97 38.56,34.03 38.56,34.03
             38.56,34.03 38.50,33.97 38.50,33.97
             38.50,33.97 38.56,33.97 38.56,33.97 Z
           M 32.28,34.13
           C 32.28,34.13 32.28,34.19 32.28,34.19
             32.28,34.19 32.22,34.13 32.22,34.13
             32.22,34.13 32.28,34.13 32.28,34.13 Z
           M 37.41,34.57
           C 37.41,34.57 37.41,34.63 37.41,34.63
             37.41,34.63 37.36,34.57 37.36,34.57
             37.36,34.57 37.41,34.57 37.41,34.57 Z
           M 40.80,34.57
           C 40.80,34.57 40.80,34.63 40.80,34.63
             40.80,34.63 40.74,34.57 40.74,34.57
             40.74,34.57 40.80,34.57 40.80,34.57 Z
           M 40.74,34.63
           C 40.74,34.63 40.74,34.68 40.74,34.68
             40.74,34.68 40.69,34.63 40.69,34.63
             40.69,34.63 40.74,34.63 40.74,34.63 Z
           M 41.18,35.01
           C 41.18,35.01 41.18,35.06 41.18,35.06
             41.18,35.06 41.13,35.01 41.13,35.01
             41.13,35.01 41.18,35.01 41.18,35.01 Z
           M 23.21,35.12
           C 23.21,35.12 23.21,35.17 23.21,35.17
             23.21,35.17 23.16,35.12 23.16,35.12
             23.16,35.12 23.21,35.12 23.21,35.12 Z
           M 41.23,35.12
           C 41.23,35.12 41.23,35.17 41.23,35.17
             41.23,35.17 41.18,35.12 41.18,35.12
             41.18,35.12 41.23,35.12 41.23,35.12 Z
           M 31.24,35.23
           C 31.24,35.23 31.24,35.28 31.24,35.28
             31.24,35.28 31.19,35.23 31.19,35.23
             31.19,35.23 31.24,35.23 31.24,35.23 Z
           M 23.43,35.34
           C 23.43,35.34 23.43,35.39 23.43,35.39
             23.43,35.39 23.38,35.34 23.38,35.34
             23.38,35.34 23.43,35.34 23.43,35.34 Z
           M 23.70,35.45
           C 23.70,35.45 23.70,35.50 23.70,35.50
             23.70,35.50 23.65,35.45 23.65,35.45
             23.65,35.45 23.70,35.45 23.70,35.45 Z
           M 23.81,35.50
           C 23.81,35.50 23.81,35.55 23.81,35.55
             23.81,35.55 23.76,35.50 23.76,35.50
             23.76,35.50 23.81,35.50 23.81,35.50 Z
           M 40.25,35.61
           C 40.25,35.61 40.25,35.66 40.25,35.66
             40.25,35.66 40.20,35.61 40.20,35.61
             40.20,35.61 40.25,35.61 40.25,35.61 Z
           M 45.11,38.45
           C 45.11,38.45 45.59,39.28 45.59,39.28
             45.59,39.28 46.56,40.85 46.56,40.85
             46.56,40.85 47.08,41.78 47.08,41.78
             46.72,41.76 46.43,41.48 46.10,41.34
             45.57,41.11 44.81,40.91 44.24,40.91
             42.30,40.91 40.40,42.33 39.85,44.18
             39.56,45.15 39.62,45.79 39.81,46.75
             39.58,46.61 39.46,46.33 39.31,46.11
             39.31,46.11 38.24,44.40 38.24,44.40
             38.24,44.40 36.83,42.16 36.83,42.16
             36.73,42.00 36.27,41.31 36.25,41.18
             36.23,41.00 36.39,40.87 36.49,40.73
             36.65,40.51 36.81,40.19 36.90,39.92
             37.04,39.52 37.12,39.09 37.07,38.67
             37.04,38.47 36.90,37.96 36.98,37.80
             37.09,37.59 37.58,37.43 37.85,37.14
             37.85,37.14 38.34,36.89 38.34,36.89
             38.34,36.89 39.43,36.16 39.43,36.16
             39.78,36.13 40.15,35.66 40.47,35.65
             40.64,35.65 41.13,36.08 41.34,36.19
             41.75,36.41 42.36,36.59 42.82,36.58
             43.09,36.59 43.62,36.40 43.84,36.58
             43.94,36.65 44.11,36.96 44.19,37.08
             44.42,37.46 44.81,38.18 45.11,38.45 Z
           M 40.85,35.66
           C 40.85,35.66 40.85,35.72 40.85,35.72
             40.85,35.72 40.80,35.66 40.80,35.66
             40.80,35.66 40.85,35.66 40.85,35.66 Z
           M 31.79,35.88
           C 31.95,36.04 31.95,36.10 31.99,36.32
             32.01,36.43 32.10,36.77 32.07,36.85
             32.03,36.98 31.84,37.09 31.73,37.16
             31.46,37.34 30.84,37.62 30.79,37.96
             30.75,38.20 31.04,38.57 31.16,38.78
             31.43,39.21 32.20,40.51 32.50,40.80
             32.50,40.80 31.13,41.84 31.13,41.84
             31.13,41.84 30.91,42.00 30.91,42.00
             30.72,42.01 30.70,42.07 30.58,42.22
             30.58,42.22 30.48,42.16 30.48,42.16
             30.48,42.16 29.93,42.71 29.93,42.71
             29.93,42.71 29.82,42.60 29.82,42.60
             29.82,42.60 29.71,42.82 29.71,42.82
             29.71,42.82 29.60,42.76 29.60,42.76
             29.60,42.76 29.38,43.04 29.38,43.04
             29.38,43.04 29.27,42.98 29.27,42.98
             29.27,42.98 29.22,43.15 29.22,43.15
             28.97,43.17 28.81,43.38 28.62,43.52
             28.62,43.52 28.24,43.73 28.24,43.73
             28.00,43.89 28.08,43.92 27.67,44.16
             27.54,44.23 27.33,44.41 27.19,44.41
             27.03,44.41 26.79,44.20 26.65,44.10
             26.27,43.84 25.90,43.55 25.45,43.42
             25.45,43.42 25.56,43.31 25.56,43.31
             25.56,43.31 24.36,42.60 24.36,42.60
             24.36,42.60 23.27,41.80 23.27,41.80
             23.27,41.80 21.46,40.57 21.46,40.57
             21.46,40.57 20.54,39.87 20.54,39.87
             20.75,39.72 20.98,39.54 21.03,39.27
             21.03,39.27 21.08,39.21 21.08,39.21
             21.08,39.21 21.19,39.27 21.19,39.27
             21.19,39.27 21.86,38.07 21.86,38.07
             22.23,37.35 22.46,36.61 22.61,35.83
             22.86,35.92 23.17,36.22 23.43,36.39
             23.94,36.72 24.40,36.94 24.96,37.17
             25.26,37.29 25.57,37.36 25.89,37.42
             27.37,37.74 28.71,37.59 30.09,36.99
             30.63,36.76 31.42,36.34 31.79,35.88 Z
           M 39.98,35.83
           C 39.98,35.83 39.98,35.88 39.98,35.88
             39.98,35.88 39.92,35.83 39.92,35.83
             39.92,35.83 39.98,35.83 39.98,35.83 Z
           M 41.02,35.88
           C 41.02,35.88 41.02,35.94 41.02,35.94
             41.02,35.94 40.96,35.88 40.96,35.88
             40.96,35.88 41.02,35.88 41.02,35.88 Z
           M 38.18,35.99
           C 38.18,35.99 38.18,36.05 38.18,36.05
             38.18,36.05 38.12,35.99 38.12,35.99
             38.12,35.99 38.18,35.99 38.18,35.99 Z
           M 39.38,36.05
           C 39.38,36.05 39.38,36.10 39.38,36.10
             39.38,36.10 39.32,36.05 39.32,36.05
             39.32,36.05 39.38,36.05 39.38,36.05 Z
           M 44.02,36.32
           C 44.02,36.32 44.02,36.37 44.02,36.37
             44.02,36.37 43.97,36.32 43.97,36.32
             43.97,36.32 44.02,36.32 44.02,36.32 Z
           M 44.07,36.43
           C 44.07,36.43 44.07,36.48 44.07,36.48
             44.07,36.48 44.02,36.43 44.02,36.43
             44.02,36.43 44.07,36.43 44.07,36.43 Z
           M 30.64,36.48
           C 30.64,36.48 30.64,36.54 30.64,36.54
             30.64,36.54 30.58,36.48 30.58,36.48
             30.58,36.48 30.64,36.48 30.64,36.48 Z
           M 38.89,36.48
           C 38.89,36.48 38.89,36.54 38.89,36.54
             38.89,36.54 38.83,36.48 38.83,36.48
             38.83,36.48 38.89,36.48 38.89,36.48 Z
           M 47.24,36.65
           C 47.24,36.65 47.24,36.70 47.24,36.70
             47.24,36.70 47.19,36.65 47.19,36.65
             47.19,36.65 47.24,36.65 47.24,36.65 Z
           M 33.92,36.70
           C 33.92,36.70 33.92,36.76 33.92,36.76
             33.92,36.76 33.86,36.70 33.86,36.70
             33.86,36.70 33.92,36.70 33.92,36.70 Z
           M 22.34,36.76
           C 22.34,36.76 22.34,36.81 22.34,36.81
             22.34,36.81 22.28,36.76 22.28,36.76
             22.28,36.76 22.34,36.76 22.34,36.76 Z
           M 33.97,36.81
           C 33.97,36.81 33.97,36.87 33.97,36.87
             33.97,36.87 33.92,36.81 33.92,36.81
             33.92,36.81 33.97,36.81 33.97,36.81 Z
           M 33.32,37.14
           C 33.32,37.14 33.32,37.19 33.32,37.19
             33.32,37.19 33.26,37.14 33.26,37.14
             33.26,37.14 33.32,37.14 33.32,37.14 Z
           M 29.11,37.25
           C 29.11,37.25 29.11,37.30 29.11,37.30
             29.11,37.30 29.06,37.25 29.06,37.25
             29.06,37.25 29.11,37.25 29.11,37.25 Z
           M 30.97,37.74
           C 30.97,37.74 30.97,37.79 30.97,37.79
             30.97,37.79 30.91,37.74 30.91,37.74
             30.91,37.74 30.97,37.74 30.97,37.74 Z
           M 51.01,38.12
           C 51.20,38.31 51.94,38.66 52.21,38.67
             52.44,38.89 52.89,39.04 53.20,39.18
             53.94,39.51 54.88,39.85 55.42,40.47
             55.72,40.82 56.03,41.38 56.28,41.78
             56.28,41.78 57.73,44.13 57.73,44.13
             57.95,44.48 58.38,45.26 58.66,45.49
             58.67,45.76 59.04,46.23 59.19,46.48
             59.19,46.48 60.28,48.23 60.28,48.23
             60.46,48.52 60.58,48.81 60.84,49.04
             60.84,49.04 60.79,49.15 60.79,49.15
             61.12,49.29 61.98,49.95 62.32,50.20
             62.44,50.29 62.73,50.48 62.76,50.63
             62.79,50.81 62.46,51.24 62.35,51.39
             62.35,51.39 60.97,53.47 60.97,53.47
             60.97,53.47 60.05,54.83 60.05,54.83
             59.96,54.96 59.78,55.31 59.62,55.35
             59.50,55.37 59.36,55.28 59.26,55.22
             59.26,55.22 58.49,54.75 58.49,54.75
             58.49,54.75 56.42,53.49 56.42,53.49
             56.17,53.35 55.75,53.02 55.49,52.98
             55.25,52.72 54.86,52.53 54.56,52.34
             54.56,52.34 52.27,50.87 52.27,50.87
             52.27,50.87 50.62,49.39 50.62,49.39
             50.62,49.39 49.54,48.44 49.54,48.44
             49.21,48.11 48.98,47.69 48.66,47.43
             48.66,47.43 48.12,47.01 48.12,47.01
             48.12,47.01 47.74,46.81 47.74,46.81
             47.74,46.81 46.86,46.03 46.86,46.03
             46.54,45.79 45.96,45.34 45.77,45.00
             45.77,45.00 45.66,45.11 45.66,45.11
             45.47,44.67 45.31,44.50 45.33,43.97
             45.36,43.23 45.97,42.68 46.70,42.71
             47.30,42.73 47.57,43.16 47.95,43.20
             48.15,43.41 48.19,43.34 48.44,43.26
             48.44,43.26 48.23,43.58 48.23,43.58
             48.23,43.58 48.39,43.58 48.39,43.58
             48.76,43.99 49.26,44.28 49.70,44.61
             49.70,44.61 50.80,45.40 50.80,45.40
             50.80,45.40 52.16,46.42 52.16,46.42
             52.37,46.57 53.03,47.10 53.25,47.11
             53.37,47.11 53.48,47.04 53.56,46.96
             53.82,46.69 53.71,46.37 53.66,46.04
             53.66,46.04 53.37,44.02 53.37,44.02
             53.33,43.73 53.35,43.39 53.13,43.16
             53.13,43.16 51.38,41.93 51.38,41.93
             51.14,41.62 51.20,40.95 50.96,40.63
             50.96,40.63 50.79,39.60 50.79,39.60
             50.79,39.60 50.41,38.01 50.41,38.01
             50.41,38.01 51.01,38.12 51.01,38.12 Z
           M 44.89,38.07
           C 44.89,38.07 44.89,38.12 44.89,38.12
             44.89,38.12 44.84,38.07 44.84,38.07
             44.84,38.07 44.89,38.07 44.89,38.07 Z
           M 47.30,38.07
           C 47.30,38.07 47.30,38.12 47.30,38.12
             47.30,38.12 47.24,38.07 47.24,38.07
             47.24,38.07 47.30,38.07 47.30,38.07 Z
           M 48.33,38.18
           C 48.33,38.18 48.33,38.23 48.33,38.23
             48.33,38.23 48.28,38.18 48.28,38.18
             48.28,38.18 48.33,38.18 48.33,38.18 Z
           M 31.95,38.34
           C 31.95,38.34 31.95,38.39 31.95,38.39
             31.95,38.39 31.90,38.34 31.90,38.34
             31.90,38.34 31.95,38.34 31.95,38.34 Z
           M 21.52,38.45
           C 21.52,38.45 21.52,38.50 21.52,38.50
             21.52,38.50 21.46,38.45 21.46,38.45
             21.46,38.45 21.52,38.45 21.52,38.45 Z
           M 31.19,38.67
           C 31.19,38.67 31.19,38.72 31.19,38.72
             31.19,38.72 31.13,38.67 31.13,38.67
             31.13,38.67 31.19,38.67 31.19,38.67 Z
           M 50.52,38.89
           C 50.52,38.89 50.52,38.94 50.52,38.94
             50.52,38.94 50.46,38.89 50.46,38.89
             50.46,38.89 50.52,38.89 50.52,38.89 Z
           M 31.35,38.94
           C 31.35,38.94 31.35,39.00 31.35,39.00
             31.35,39.00 31.29,38.94 31.29,38.94
             31.29,38.94 31.35,38.94 31.35,38.94 Z
           M 52.98,38.94
           C 52.98,38.94 52.98,39.00 52.98,39.00
             52.98,39.00 52.92,38.94 52.92,38.94
             52.92,38.94 52.98,38.94 52.98,38.94 Z
           M 31.68,39.32
           C 31.68,39.32 31.68,39.38 31.68,39.38
             31.68,39.38 31.62,39.32 31.62,39.32
             31.62,39.32 31.68,39.32 31.68,39.32 Z
           M 49.10,39.38
           C 49.10,39.38 49.10,39.43 49.10,39.43
             49.10,39.43 49.04,39.38 49.04,39.38
             49.04,39.38 49.10,39.38 49.10,39.38 Z
           M 49.15,39.49
           C 49.15,39.49 49.15,39.54 49.15,39.54
             49.15,39.54 49.10,39.49 49.10,39.49
             49.10,39.49 49.15,39.49 49.15,39.49 Z
           M 46.81,39.60
           C 46.81,39.60 46.81,39.65 46.81,39.65
             46.81,39.65 46.75,39.60 46.75,39.60
             46.75,39.60 46.81,39.60 46.81,39.60 Z
           M 46.04,39.87
           C 46.04,39.87 46.04,39.92 46.04,39.92
             46.04,39.92 45.99,39.87 45.99,39.87
             45.99,39.87 46.04,39.87 46.04,39.87 Z
           M 47.02,39.92
           C 47.02,39.92 47.02,39.98 47.02,39.98
             47.02,39.98 46.97,39.92 46.97,39.92
             46.97,39.92 47.02,39.92 47.02,39.92 Z
           M 50.96,40.42
           C 50.96,40.42 50.96,40.47 50.96,40.47
             50.96,40.47 50.90,40.42 50.90,40.42
             50.90,40.42 50.96,40.42 50.96,40.42 Z
           M 33.26,40.52
           C 33.26,40.52 33.26,40.58 33.26,40.58
             33.26,40.58 33.21,40.52 33.21,40.52
             33.21,40.52 33.26,40.52 33.26,40.52 Z
           M 36.54,40.52
           C 36.54,40.52 36.54,40.58 36.54,40.58
             36.54,40.58 36.48,40.52 36.48,40.52
             36.48,40.52 36.54,40.52 36.54,40.52 Z
           M 20.32,40.96
           C 20.32,40.96 20.43,40.91 20.43,40.91
             20.43,40.91 20.48,41.07 20.48,41.07
             20.48,41.07 20.64,41.18 20.64,41.18
             20.64,41.18 20.75,41.13 20.75,41.13
             20.75,41.13 21.57,41.84 21.57,41.84
             21.57,41.84 22.17,42.11 22.17,42.11
             22.17,42.11 23.37,42.98 23.37,42.98
             23.37,42.98 23.98,43.31 23.98,43.31
             23.98,43.31 24.41,43.75 24.41,43.75
             24.41,43.75 24.58,43.64 24.58,43.64
             24.58,43.64 24.52,43.80 24.52,43.80
             24.52,43.80 24.80,44.02 24.80,44.02
             24.80,44.02 24.09,44.68 24.09,44.68
             24.09,44.68 23.98,44.62 23.98,44.62
             23.98,44.62 23.98,44.57 23.98,44.57
             23.98,44.57 23.43,45.11 23.43,45.11
             23.43,45.11 23.27,45.06 23.27,45.06
             23.27,45.06 21.96,46.26 21.96,46.26
             21.96,46.26 21.74,46.26 21.74,46.26
             21.74,46.26 21.57,46.10 21.57,46.10
             21.55,45.88 21.41,45.78 21.25,45.66
             21.25,45.66 21.25,45.39 21.25,45.39
             21.12,45.35 21.14,45.35 21.14,45.22
             20.90,45.09 20.73,44.83 20.70,44.57
             20.46,44.43 20.25,44.07 20.21,43.80
             20.21,43.80 20.04,43.75 20.04,43.75
             20.04,43.75 19.72,43.04 19.72,43.04
             19.47,42.89 19.27,42.55 19.12,42.30
             19.03,42.15 19.01,42.00 18.84,41.94
             18.84,41.94 18.57,41.40 18.57,41.40
             18.57,41.40 19.12,41.07 19.12,41.07
             19.49,40.82 20.02,40.35 20.32,40.96 Z
           M 55.87,40.85
           C 55.87,40.85 55.87,40.91 55.87,40.91
             55.87,40.91 55.82,40.85 55.82,40.85
             55.82,40.85 55.87,40.85 55.87,40.85 Z
           M 21.96,40.91
           C 21.96,40.91 21.96,40.96 21.96,40.96
             21.96,40.96 21.90,40.91 21.90,40.91
             21.90,40.91 21.96,40.91 21.96,40.91 Z
           M 47.68,40.96
           C 47.68,40.96 47.68,41.02 47.68,41.02
             47.68,41.02 47.62,40.96 47.62,40.96
             47.62,40.96 47.68,40.96 47.68,40.96 Z
           M 46.86,41.13
           C 46.86,41.13 46.86,41.18 46.86,41.18
             46.86,41.18 46.81,41.13 46.81,41.13
             46.81,41.13 46.86,41.13 46.86,41.13 Z
           M 18.41,41.29
           C 18.41,41.29 18.41,41.34 18.41,41.34
             18.41,41.34 18.35,41.29 18.35,41.29
             18.35,41.29 18.41,41.29 18.41,41.29 Z
           M 22.56,41.40
           C 22.56,41.40 22.56,41.45 22.56,41.45
             22.56,41.45 22.50,41.40 22.50,41.40
             22.50,41.40 22.56,41.40 22.56,41.40 Z
           M 31.84,41.40
           C 31.84,41.40 31.84,41.45 31.84,41.45
             31.84,41.45 31.79,41.40 31.79,41.40
             31.79,41.40 31.84,41.40 31.84,41.40 Z
           M 18.57,41.45
           C 18.57,41.45 18.57,41.51 18.57,41.51
             18.57,41.51 18.51,41.45 18.51,41.45
             18.51,41.45 18.57,41.45 18.57,41.45 Z
           M 47.13,41.56
           C 47.13,41.56 47.13,41.62 47.13,41.62
             47.13,41.62 47.08,41.56 47.08,41.56
             47.08,41.56 47.13,41.56 47.13,41.56 Z
           M 32.22,46.04
           C 32.22,46.04 31.13,45.33 31.13,45.33
             31.13,45.33 29.38,44.13 29.38,44.13
             29.60,43.88 29.98,43.66 30.26,43.47
             30.26,43.47 31.90,42.38 31.90,42.38
             32.06,42.26 32.72,41.79 32.88,41.78
             33.04,41.77 33.13,41.93 33.21,42.05
             33.36,42.28 33.90,43.08 33.88,43.31
             33.86,43.49 33.52,43.95 33.41,44.13
             33.41,44.13 32.22,46.04 32.22,46.04 Z
           M 41.73,41.73
           C 41.73,41.73 41.73,41.78 41.73,41.78
             41.73,41.78 41.67,41.73 41.67,41.73
             41.67,41.73 41.73,41.73 41.73,41.73 Z
           M 23.92,45.82
           C 23.92,45.82 23.87,45.71 23.87,45.71
             23.87,45.71 24.03,45.71 24.03,45.71
             24.03,45.71 24.14,45.60 24.14,45.60
             24.14,45.60 24.69,45.00 24.69,45.00
             24.69,45.00 25.51,44.51 25.51,44.51
             25.51,44.51 25.94,44.62 25.94,44.62
             25.94,44.62 25.89,44.73 25.89,44.73
             26.05,44.81 26.45,44.99 26.55,45.12
             26.69,45.31 26.68,45.80 26.69,46.04
             26.69,46.04 26.69,46.59 26.69,46.59
             26.69,46.59 26.69,46.97 26.69,46.97
             26.69,46.97 26.76,47.46 26.76,47.46
             26.76,47.46 26.93,47.41 26.93,47.41
             26.93,47.41 26.82,47.67 26.82,47.67
             26.82,47.67 26.65,49.10 26.65,49.10
             26.65,49.10 26.65,49.81 26.65,49.81
             26.65,49.81 26.65,52.81 26.65,52.81
             26.65,52.81 26.65,54.18 26.65,54.18
             26.65,54.40 26.63,54.49 26.76,54.67
             26.76,54.67 27.03,55.05 27.03,55.05
             27.03,55.05 24.09,55.05 24.09,55.05
             24.09,55.05 18.19,55.05 18.19,55.05
             18.19,55.05 14.82,55.05 14.82,55.05
             14.82,55.05 14.20,55.05 14.20,55.05
             14.20,55.05 11.52,55.05 11.52,55.05
             11.52,55.05 9.23,55.05 9.23,55.05
             9.14,54.72 9.18,54.21 9.18,53.85
             9.18,53.85 9.18,51.67 9.18,51.67
             9.18,49.57 8.93,47.63 10.11,45.77
             10.33,45.42 10.52,45.14 10.81,44.84
             11.08,44.57 11.43,44.23 11.74,44.02
             12.70,43.38 13.52,43.17 14.58,42.80
             14.58,42.80 16.71,42.08 16.71,42.08
             16.89,42.01 17.48,41.78 17.64,41.82
             17.77,41.86 17.85,42.00 17.93,42.11
             17.93,42.11 18.50,42.98 18.50,42.98
             18.50,42.98 20.57,46.15 20.57,46.15
             20.57,46.15 21.21,47.13 21.21,47.13
             21.33,47.29 21.46,47.45 21.68,47.44
             21.92,47.43 22.22,47.16 22.39,47.01
             22.75,46.72 23.55,45.98 23.92,45.82 Z
           M 31.08,41.89
           C 31.08,41.89 31.08,41.94 31.08,41.94
             31.08,41.94 31.02,41.89 31.02,41.89
             31.02,41.89 31.08,41.89 31.08,41.89 Z
           M 45.39,42.11
           C 44.98,42.53 44.79,42.62 44.53,43.20
             44.36,43.57 44.39,43.86 44.29,44.00
             44.29,44.00 43.98,44.30 43.98,44.30
             43.75,44.55 43.62,44.89 43.64,45.22
             43.66,45.62 43.83,45.72 43.87,45.93
             43.92,46.17 43.72,46.33 43.53,46.25
             43.25,46.13 43.13,45.48 42.71,45.44
             42.45,45.42 42.21,45.62 42.20,45.88
             42.18,46.01 42.25,46.13 42.20,46.25
             42.13,46.43 41.63,46.68 41.97,47.06
             42.28,47.43 42.58,47.09 42.82,47.06
             42.99,47.04 43.48,47.26 43.86,47.21
             43.86,47.21 44.57,46.91 44.57,46.91
             44.57,46.91 44.77,46.44 44.77,46.44
             44.77,46.44 44.89,45.93 44.89,45.93
             44.89,45.93 44.73,45.99 44.73,45.99
             44.87,45.47 44.69,45.62 44.62,45.11
             44.62,45.11 45.33,45.93 45.33,45.93
             45.33,45.93 45.49,45.88 45.49,45.88
             45.49,45.88 45.71,46.26 45.71,46.26
             45.71,46.26 45.99,46.26 45.99,46.26
             45.99,46.26 45.82,46.31 45.82,46.31
             45.82,46.31 46.10,46.48 46.10,46.48
             46.25,46.75 46.92,47.33 47.19,47.57
             46.96,47.93 46.70,48.22 46.31,48.42
             46.31,48.42 45.77,48.62 45.77,48.62
             45.58,48.72 45.53,48.85 45.42,48.93
             45.29,49.02 45.04,49.06 44.89,49.09
             44.40,49.15 44.19,49.17 43.69,49.09
             43.51,49.07 43.34,48.98 43.26,49.15
             43.04,48.93 42.74,48.82 42.44,48.77
             42.05,48.35 41.72,48.30 41.24,47.57
             40.36,46.23 40.43,44.42 41.46,43.18
             41.69,42.90 42.02,42.62 42.33,42.43
             42.55,42.30 42.78,42.23 42.98,42.05
             42.98,42.05 44.02,41.90 44.02,41.90
             44.43,41.86 45.01,41.95 45.39,42.11 Z
           M 56.47,41.94
           C 56.47,41.94 56.47,42.00 56.47,42.00
             56.47,42.00 56.42,41.94 56.42,41.94
             56.42,41.94 56.47,41.94 56.47,41.94 Z
           M 48.12,42.05
           C 48.12,42.05 48.12,42.11 48.12,42.11
             48.12,42.11 48.06,42.05 48.06,42.05
             48.06,42.05 48.12,42.05 48.12,42.05 Z
           M 34.35,42.11
           C 34.35,42.11 34.35,42.16 34.35,42.16
             34.35,42.16 34.30,42.11 34.30,42.11
             34.30,42.11 34.35,42.11 34.35,42.11 Z
           M 41.29,42.11
           C 41.29,42.11 41.29,42.16 41.29,42.16
             41.29,42.16 41.23,42.11 41.23,42.11
             41.23,42.11 41.29,42.11 41.29,42.11 Z
           M 32.11,42.16
           C 32.11,42.16 32.11,42.22 32.11,42.22
             32.11,42.22 32.06,42.16 32.06,42.16
             32.06,42.16 32.11,42.16 32.11,42.16 Z
           M 33.37,42.16
           C 33.37,42.16 33.37,42.22 33.37,42.22
             33.37,42.22 33.32,42.16 33.32,42.16
             33.32,42.16 33.37,42.16 33.37,42.16 Z
           M 45.33,42.22
           C 45.33,42.22 45.33,42.27 45.33,42.27
             45.33,42.27 45.28,42.22 45.28,42.22
             45.28,42.22 45.33,42.22 45.33,42.22 Z
           M 31.95,42.27
           C 31.95,42.27 31.95,42.33 31.95,42.33
             31.95,42.33 31.90,42.27 31.90,42.27
             31.90,42.27 31.95,42.27 31.95,42.27 Z
           M 36.92,42.33
           C 36.92,42.33 36.92,42.38 36.92,42.38
             36.92,42.38 36.87,42.33 36.87,42.33
             36.87,42.33 36.92,42.33 36.92,42.33 Z
           M 47.90,42.33
           C 47.90,42.33 47.90,42.38 47.90,42.38
             47.90,42.38 47.84,42.33 47.84,42.33
             47.84,42.33 47.90,42.33 47.90,42.33 Z
           M 47.79,42.38
           C 47.79,42.38 47.79,42.44 47.79,42.44
             47.79,42.44 47.73,42.38 47.73,42.38
             47.73,42.38 47.79,42.38 47.79,42.38 Z
           M 18.30,42.44
           C 18.30,42.44 18.30,42.49 18.30,42.49
             18.30,42.49 18.24,42.44 18.24,42.44
             18.24,42.44 18.30,42.44 18.30,42.44 Z
           M 24.14,42.44
           C 24.14,42.44 24.14,42.49 24.14,42.49
             24.14,42.49 24.09,42.44 24.09,42.44
             24.09,42.44 24.14,42.44 24.14,42.44 Z
           M 33.53,42.44
           C 33.53,42.44 33.53,42.49 33.53,42.49
             33.53,42.49 33.48,42.44 33.48,42.44
             33.48,42.44 33.53,42.44 33.53,42.44 Z
           M 47.68,42.49
           C 47.68,42.49 47.68,42.55 47.68,42.55
             47.68,42.55 47.62,42.49 47.62,42.49
             47.62,42.49 47.68,42.49 47.68,42.49 Z
           M 37.03,42.55
           C 37.03,42.55 37.03,42.60 37.03,42.60
             37.03,42.60 36.97,42.55 36.97,42.55
             36.97,42.55 37.03,42.55 37.03,42.55 Z
           M 47.62,42.60
           C 47.62,42.60 47.62,42.65 47.62,42.65
             47.62,42.65 47.57,42.60 47.57,42.60
             47.57,42.60 47.62,42.60 47.62,42.60 Z
           M 44.95,42.65
           C 44.95,42.65 44.95,42.71 44.95,42.71
             44.95,42.71 44.89,42.65 44.89,42.65
             44.89,42.65 44.95,42.65 44.95,42.65 Z
           M 47.57,42.65
           C 47.57,42.65 47.57,42.71 47.57,42.71
             47.57,42.71 47.52,42.65 47.52,42.65
             47.52,42.65 47.57,42.65 47.57,42.65 Z
           M 48.06,42.65
           C 48.06,42.65 48.06,42.71 48.06,42.71
             48.06,42.71 48.01,42.65 48.01,42.65
             48.01,42.65 48.06,42.65 48.06,42.65 Z
           M 24.25,42.71
           C 24.25,42.71 24.25,42.76 24.25,42.76
             24.25,42.76 24.19,42.71 24.19,42.71
             24.19,42.71 24.25,42.71 24.25,42.71 Z
           M 47.52,42.71
           C 47.52,42.71 47.52,42.76 47.52,42.76
             47.52,42.76 47.46,42.71 47.46,42.71
             47.46,42.71 47.52,42.71 47.52,42.71 Z
           M 47.46,42.76
           C 47.46,42.76 47.46,42.82 47.46,42.82
             47.46,42.82 47.41,42.76 47.41,42.76
             47.41,42.76 47.46,42.76 47.46,42.76 Z
           M 47.95,42.76
           C 47.95,42.76 47.95,42.82 47.95,42.82
             47.95,42.82 47.90,42.76 47.90,42.76
             47.90,42.76 47.95,42.76 47.95,42.76 Z
           M 47.41,42.82
           C 47.41,42.82 47.41,42.87 47.41,42.87
             47.41,42.87 47.35,42.82 47.35,42.82
             47.35,42.82 47.41,42.82 47.41,42.82 Z
           M 47.90,42.82
           C 47.90,42.82 47.90,42.87 47.90,42.87
             47.90,42.87 47.84,42.82 47.84,42.82
             47.84,42.82 47.90,42.82 47.90,42.82 Z
           M 48.33,42.82
           C 48.33,42.82 48.33,42.87 48.33,42.87
             48.33,42.87 48.28,42.82 48.28,42.82
             48.28,42.82 48.33,42.82 48.33,42.82 Z
           M 47.84,42.87
           C 47.84,42.87 47.84,42.93 47.84,42.93
             47.84,42.93 47.79,42.87 47.79,42.87
             47.79,42.87 47.84,42.87 47.84,42.87 Z
           M 47.79,42.93
           C 47.79,42.93 47.79,42.98 47.79,42.98
             47.79,42.98 47.73,42.93 47.73,42.93
             47.73,42.93 47.79,42.93 47.79,42.93 Z
           M 25.01,42.98
           C 25.01,42.98 25.01,43.04 25.01,43.04
             25.01,43.04 24.96,42.98 24.96,42.98
             24.96,42.98 25.01,42.98 25.01,42.98 Z
           M 47.73,42.98
           C 47.73,42.98 47.73,43.04 47.73,43.04
             47.73,43.04 47.68,42.98 47.68,42.98
             47.68,42.98 47.73,42.98 47.73,42.98 Z
           M 34.03,43.04
           C 34.03,43.04 34.03,43.09 34.03,43.09
             34.03,43.09 33.97,43.04 33.97,43.04
             33.97,43.04 34.03,43.04 34.03,43.04 Z
           M 25.18,43.09
           C 25.18,43.09 25.18,43.15 25.18,43.15
             25.18,43.15 25.12,43.09 25.12,43.09
             25.12,43.09 25.18,43.09 25.18,43.09 Z
           M 48.50,43.15
           C 48.50,43.15 48.50,43.20 48.50,43.20
             48.50,43.20 48.44,43.15 48.44,43.15
             48.44,43.15 48.50,43.15 48.50,43.15 Z
           M 24.96,43.20
           C 24.96,43.20 24.96,43.26 24.96,43.26
             24.96,43.26 24.90,43.20 24.90,43.20
             24.90,43.20 24.96,43.20 24.96,43.20 Z
           M 25.45,43.31
           C 25.45,43.31 25.45,43.36 25.45,43.36
             25.45,43.36 25.40,43.31 25.40,43.31
             25.40,43.31 25.45,43.31 25.45,43.31 Z
           M 49.48,43.31
           C 49.48,43.31 49.48,43.36 49.48,43.36
             49.48,43.36 49.43,43.31 49.43,43.31
             49.43,43.31 49.48,43.31 49.48,43.31 Z
           M 57.35,43.31
           C 57.35,43.31 57.35,43.36 57.35,43.36
             57.35,43.36 57.29,43.31 57.29,43.31
             57.29,43.31 57.35,43.31 57.35,43.31 Z
           M 51.72,43.36
           C 51.89,43.45 52.22,43.63 52.33,43.78
             52.44,43.94 52.48,44.52 52.49,44.73
             52.27,44.53 51.68,43.64 51.72,43.36 Z
           M 30.20,43.42
           C 30.20,43.42 30.20,43.47 30.20,43.47
             30.20,43.47 30.15,43.42 30.15,43.42
             30.15,43.42 30.20,43.42 30.20,43.42 Z
           M 57.40,43.42
           C 57.40,43.42 57.40,43.47 57.40,43.47
             57.40,43.47 57.35,43.42 57.35,43.42
             57.35,43.42 57.40,43.42 57.40,43.42 Z
           M 37.68,43.53
           C 37.68,43.53 37.68,43.58 37.68,43.58
             37.68,43.58 37.63,43.53 37.63,43.53
             37.63,43.53 37.68,43.53 37.68,43.53 Z
           M 24.41,43.58
           C 24.41,43.58 24.41,43.64 24.41,43.64
             24.41,43.64 24.36,43.58 24.36,43.58
             24.36,43.58 24.41,43.58 24.41,43.58 Z
           M 48.94,43.91
           C 48.94,43.91 48.94,43.97 48.94,43.97
             48.94,43.97 48.88,43.91 48.88,43.91
             48.88,43.91 48.94,43.91 48.94,43.91 Z
           M 33.53,44.02
           C 33.53,44.02 33.53,44.07 33.53,44.07
             33.53,44.07 33.48,44.02 33.48,44.02
             33.48,44.02 33.53,44.02 33.53,44.02 Z
           M 39.98,44.02
           C 39.98,44.02 39.98,44.07 39.98,44.07
             39.98,44.07 39.92,44.02 39.92,44.02
             39.92,44.02 39.98,44.02 39.98,44.02 Z
           M 50.30,44.02
           C 50.30,44.02 50.30,44.07 50.30,44.07
             50.30,44.07 50.25,44.02 50.25,44.02
             50.25,44.02 50.30,44.02 50.30,44.02 Z
           M 27.96,44.07
           C 27.96,44.07 27.96,44.13 27.96,44.13
             27.96,44.13 27.91,44.07 27.91,44.07
             27.91,44.07 27.96,44.07 27.96,44.07 Z
           M 33.59,44.07
           C 33.59,44.07 33.59,44.13 33.59,44.13
             33.59,44.13 33.53,44.07 33.53,44.07
             33.53,44.07 33.59,44.07 33.59,44.07 Z
           M 37.96,44.07
           C 37.96,44.07 37.96,44.13 37.96,44.13
             37.96,44.13 37.90,44.07 37.90,44.07
             37.90,44.07 37.96,44.07 37.96,44.07 Z
           M 19.33,44.13
           C 19.33,44.13 19.33,44.18 19.33,44.18
             19.33,44.18 19.28,44.13 19.28,44.13
             19.28,44.13 19.33,44.13 19.33,44.13 Z
           M 34.74,44.18
           C 34.74,44.18 34.74,44.24 34.74,44.24
             34.74,44.24 34.68,44.18 34.68,44.18
             34.68,44.18 34.74,44.18 34.74,44.18 Z
           M 34.57,44.24
           C 34.57,44.24 36.70,47.68 36.70,47.68
             36.70,47.68 37.32,48.66 37.32,48.66
             37.32,48.66 37.63,49.21 37.63,49.21
             37.63,49.21 37.74,49.21 37.74,49.21
             37.74,49.21 37.68,49.32 37.68,49.32
             37.68,49.32 37.85,49.37 37.85,49.37
             37.85,49.37 37.79,49.48 37.79,49.48
             37.79,49.48 38.12,49.81 38.12,49.81
             38.12,49.81 38.07,49.92 38.07,49.92
             38.26,50.04 38.59,50.57 38.61,50.79
             38.78,50.91 38.81,50.97 38.83,51.17
             39.09,51.35 39.58,52.26 39.80,52.59
             39.94,52.81 40.07,52.93 40.09,53.20
             40.09,53.20 40.42,53.47 40.42,53.47
             40.42,53.71 40.55,53.82 40.67,54.01
             40.89,54.34 41.14,54.67 41.23,55.05
             41.23,55.05 31.73,55.05 31.73,55.05
             31.73,55.05 28.84,55.05 28.84,55.05
             28.84,55.05 27.31,55.00 27.31,55.00
             27.41,54.88 27.54,54.76 27.60,54.62
             27.67,54.42 27.64,53.26 27.64,52.98
             27.64,52.98 27.64,49.97 27.64,49.97
             27.64,49.97 27.60,47.90 27.60,47.90
             27.57,47.52 27.75,47.16 27.53,46.75
             27.77,46.29 27.53,45.61 27.67,45.29
             27.74,45.12 27.98,45.02 28.18,44.78
             28.18,44.78 28.51,44.68 28.51,44.68
             28.65,44.69 29.28,45.17 29.44,45.28
             29.44,45.28 31.35,46.63 31.35,46.63
             31.55,46.77 32.11,47.21 32.33,47.21
             32.62,47.20 32.88,46.75 33.02,46.53
             33.02,46.53 34.46,44.24 34.46,44.24
             34.46,44.24 34.57,44.24 34.57,44.24 Z
           M 27.69,44.29
           C 27.69,44.29 27.69,44.35 27.69,44.35
             27.69,44.35 27.64,44.29 27.64,44.29
             27.64,44.29 27.69,44.29 27.69,44.29 Z
           M 19.55,44.46
           C 19.55,44.46 19.55,44.51 19.55,44.51
             19.55,44.51 19.50,44.46 19.50,44.46
             19.50,44.46 19.55,44.46 19.55,44.46 Z
           M 27.36,44.46
           C 27.36,44.46 27.36,44.51 27.36,44.51
             27.36,44.51 27.31,44.46 27.31,44.46
             27.31,44.46 27.36,44.46 27.36,44.46 Z
           M 50.96,44.46
           C 50.96,44.46 50.96,44.51 50.96,44.51
             50.96,44.51 50.90,44.46 50.90,44.46
             50.90,44.46 50.96,44.46 50.96,44.46 Z
           M 52.27,44.51
           C 52.27,44.51 52.27,44.57 52.27,44.57
             52.27,44.57 52.21,44.51 52.21,44.51
             52.21,44.51 52.27,44.51 52.27,44.51 Z
           M 28.45,44.57
           C 28.45,44.57 28.45,44.62 28.45,44.62
             28.45,44.62 28.40,44.57 28.40,44.57
             28.40,44.57 28.45,44.57 28.45,44.57 Z
           M 25.01,44.68
           C 25.01,44.68 25.01,44.73 25.01,44.73
             25.01,44.73 24.96,44.68 24.96,44.68
             24.96,44.68 25.01,44.68 25.01,44.68 Z
           M 28.18,44.73
           C 28.18,44.73 28.18,44.78 28.18,44.78
             28.18,44.78 28.13,44.73 28.13,44.73
             28.13,44.73 28.18,44.73 28.18,44.73 Z
           M 26.27,44.78
           C 26.27,44.78 26.27,44.84 26.27,44.84
             26.27,44.84 26.22,44.78 26.22,44.78
             26.22,44.78 26.27,44.78 26.27,44.78 Z
           M 45.44,44.78
           C 45.44,44.78 45.44,44.84 45.44,44.84
             45.44,44.84 45.39,44.78 45.39,44.78
             45.39,44.78 45.44,44.78 45.44,44.78 Z
           M 26.32,44.84
           C 26.32,44.84 26.32,44.89 26.32,44.89
             26.32,44.89 26.27,44.84 26.27,44.84
             26.27,44.84 26.32,44.84 26.32,44.84 Z
           M 24.85,44.89
           C 24.85,44.89 24.85,44.95 24.85,44.95
             24.85,44.95 24.80,44.89 24.80,44.89
             24.80,44.89 24.85,44.89 24.85,44.89 Z
           M 26.38,44.89
           C 26.38,44.89 26.38,44.95 26.38,44.95
             26.38,44.95 26.32,44.89 26.32,44.89
             26.32,44.89 26.38,44.89 26.38,44.89 Z
           M 28.02,44.89
           C 28.02,44.89 28.02,44.95 28.02,44.95
             28.02,44.95 27.96,44.89 27.96,44.89
             27.96,44.89 28.02,44.89 28.02,44.89 Z
           M 24.80,44.95
           C 24.80,44.95 24.80,45.00 24.80,45.00
             24.80,45.00 24.74,44.95 24.74,44.95
             24.74,44.95 24.80,44.95 24.80,44.95 Z
           M 45.28,44.95
           C 45.28,44.95 45.28,45.00 45.28,45.00
             45.28,45.00 45.22,44.95 45.22,44.95
             45.22,44.95 45.28,44.95 45.28,44.95 Z
           M 45.55,44.95
           C 45.55,44.95 45.55,45.00 45.55,45.00
             45.55,45.00 45.49,44.95 45.49,44.95
             45.49,44.95 45.55,44.95 45.55,44.95 Z
           M 27.64,45.06
           C 27.64,45.06 27.64,45.11 27.64,45.11
             27.64,45.11 27.58,45.06 27.58,45.06
             27.58,45.06 27.64,45.06 27.64,45.06 Z
           M 27.80,45.06
           C 27.80,45.06 27.80,45.11 27.80,45.11
             27.80,45.11 27.74,45.06 27.74,45.06
             27.74,45.06 27.80,45.06 27.80,45.06 Z
           M 23.59,45.11
           C 23.59,45.11 23.59,45.17 23.59,45.17
             23.59,45.17 23.54,45.11 23.54,45.11
             23.54,45.11 23.59,45.11 23.59,45.11 Z
           M 27.58,45.11
           C 27.58,45.11 27.58,45.17 27.58,45.17
             27.58,45.17 27.53,45.11 27.53,45.11
             27.53,45.11 27.58,45.11 27.58,45.11 Z
           M 45.49,45.17
           C 45.49,45.17 45.49,45.22 45.49,45.22
             45.49,45.22 45.44,45.17 45.44,45.17
             45.44,45.17 45.49,45.17 45.49,45.17 Z
           M 23.48,45.22
           C 23.48,45.22 23.48,45.28 23.48,45.28
             23.48,45.28 23.43,45.22 23.43,45.22
             23.43,45.22 23.48,45.22 23.48,45.22 Z
           M 29.55,45.22
           C 29.55,45.22 29.55,45.28 29.55,45.28
             29.55,45.28 29.49,45.22 29.49,45.22
             29.49,45.22 29.55,45.22 29.55,45.22 Z
           M 45.88,45.22
           C 45.88,45.22 45.88,45.28 45.88,45.28
             45.88,45.28 45.82,45.22 45.82,45.22
             45.82,45.22 45.88,45.22 45.88,45.22 Z
           M 45.44,45.28
           C 45.44,45.28 45.44,45.33 45.44,45.33
             45.44,45.33 45.39,45.28 45.39,45.28
             45.39,45.28 45.44,45.28 45.44,45.28 Z
           M 45.99,45.28
           C 45.99,45.28 45.99,45.33 45.99,45.33
             45.99,45.33 45.93,45.28 45.93,45.28
             45.93,45.28 45.99,45.28 45.99,45.28 Z
           M 23.10,45.33
           C 23.10,45.33 23.10,45.39 23.10,45.39
             23.10,45.39 23.05,45.33 23.05,45.33
             23.05,45.33 23.10,45.33 23.10,45.33 Z
           M 29.66,45.33
           C 29.66,45.33 29.66,45.39 29.66,45.39
             29.66,45.39 29.60,45.33 29.60,45.33
             29.60,45.33 29.66,45.33 29.66,45.33 Z
           M 32.71,45.33
           C 32.71,45.33 32.71,45.39 32.71,45.39
             32.71,45.39 32.66,45.33 32.66,45.33
             32.66,45.33 32.71,45.33 32.71,45.33 Z
           M 38.72,45.33
           C 38.72,45.33 38.72,45.39 38.72,45.39
             38.72,45.39 38.67,45.33 38.67,45.33
             38.67,45.33 38.72,45.33 38.72,45.33 Z
           M 45.71,45.39
           C 45.71,45.39 45.71,45.44 45.71,45.44
             45.71,45.44 45.66,45.39 45.66,45.39
             45.66,45.39 45.71,45.39 45.71,45.39 Z
           M 45.66,45.44
           C 45.66,45.44 45.66,45.49 45.66,45.49
             45.66,45.49 45.60,45.44 45.60,45.44
             45.60,45.44 45.66,45.44 45.66,45.44 Z
           M 46.10,45.44
           C 46.10,45.44 46.10,45.49 46.10,45.49
             46.10,45.49 46.04,45.44 46.04,45.44
             46.04,45.44 46.10,45.44 46.10,45.44 Z
           M 31.29,45.55
           C 31.29,45.55 31.29,45.60 31.29,45.60
             31.29,45.60 31.24,45.55 31.24,45.55
             31.24,45.55 31.29,45.55 31.29,45.55 Z
           M 38.01,45.55
           C 38.01,45.55 38.01,45.60 38.01,45.60
             38.01,45.60 37.96,45.55 37.96,45.55
             37.96,45.55 38.01,45.55 38.01,45.55 Z
           M 20.32,45.60
           C 20.32,45.60 20.32,45.66 20.32,45.66
             20.32,45.66 20.26,45.60 20.26,45.60
             20.26,45.60 20.32,45.60 20.32,45.60 Z
           M 32.55,45.60
           C 32.55,45.60 32.55,45.66 32.55,45.66
             32.55,45.66 32.50,45.60 32.50,45.60
             32.50,45.60 32.55,45.60 32.55,45.60 Z
           M 42.38,45.60
           C 42.38,45.60 42.38,45.66 42.38,45.66
             42.38,45.66 42.33,45.60 42.33,45.60
             42.33,45.60 42.38,45.60 42.38,45.60 Z
           M 42.98,45.60
           C 42.98,45.60 42.98,45.66 42.98,45.66
             42.98,45.66 42.93,45.60 42.93,45.60
             42.93,45.60 42.98,45.60 42.98,45.60 Z
           M 23.43,45.66
           C 23.43,45.66 23.43,45.71 23.43,45.71
             23.43,45.71 23.38,45.66 23.38,45.66
             23.38,45.66 23.43,45.66 23.43,45.66 Z
           M 45.33,45.66
           C 45.33,45.66 45.33,45.71 45.33,45.71
             45.33,45.71 45.28,45.66 45.28,45.66
             45.28,45.66 45.33,45.66 45.33,45.66 Z
           M 44.51,45.71
           C 44.51,45.71 44.51,45.77 44.51,45.77
             44.51,45.77 44.46,45.71 44.46,45.71
             44.46,45.71 44.51,45.71 44.51,45.71 Z
           M 45.44,45.71
           C 45.44,45.71 45.44,45.77 45.44,45.77
             45.44,45.77 45.39,45.71 45.39,45.71
             45.39,45.71 45.44,45.71 45.44,45.71 Z
           M 23.81,45.77
           C 23.81,45.77 23.81,45.82 23.81,45.82
             23.81,45.82 23.76,45.77 23.76,45.77
             23.76,45.77 23.81,45.77 23.81,45.77 Z
           M 44.46,45.77
           C 44.46,45.77 44.46,45.82 44.46,45.82
             44.46,45.82 44.40,45.77 44.40,45.77
             44.40,45.77 44.46,45.77 44.46,45.77 Z
           M 46.37,45.77
           C 46.37,45.77 46.37,45.82 46.37,45.82
             46.37,45.82 46.31,45.77 46.31,45.77
             46.31,45.77 46.37,45.77 46.37,45.77 Z
           M 46.48,45.77
           C 46.48,45.77 46.48,45.82 46.48,45.82
             46.48,45.82 46.42,45.77 46.42,45.77
             46.42,45.77 46.48,45.77 46.48,45.77 Z
           M 27.31,45.82
           C 27.31,45.82 27.31,45.88 27.31,45.88
             27.31,45.88 27.25,45.82 27.25,45.82
             27.25,45.82 27.31,45.82 27.31,45.82 Z
           M 32.50,45.82
           C 32.50,45.82 32.50,45.88 32.50,45.88
             32.50,45.88 32.44,45.82 32.44,45.82
             32.44,45.82 32.50,45.82 32.50,45.82 Z
           M 46.31,45.82
           C 46.31,45.82 46.31,45.88 46.31,45.88
             46.31,45.88 46.26,45.82 46.26,45.82
             46.26,45.82 46.31,45.82 46.31,45.82 Z
           M 23.59,45.93
           C 23.59,45.93 23.59,45.99 23.59,45.99
             23.59,45.99 23.54,45.93 23.54,45.93
             23.54,45.93 23.59,45.93 23.59,45.93 Z
           M 51.72,45.99
           C 51.72,45.99 51.72,46.04 51.72,46.04
             51.72,46.04 51.67,45.99 51.67,45.99
             51.67,45.99 51.72,45.99 51.72,45.99 Z
           M 26.98,46.04
           C 26.98,46.04 26.98,46.10 26.98,46.10
             26.98,46.10 26.93,46.04 26.93,46.04
             26.93,46.04 26.98,46.04 26.98,46.04 Z
           M 31.95,46.04
           C 31.95,46.04 31.95,46.10 31.95,46.10
             31.95,46.10 31.90,46.04 31.90,46.04
             31.90,46.04 31.95,46.04 31.95,46.04 Z
           M 43.31,46.04
           C 43.31,46.04 43.31,46.10 43.31,46.10
             43.31,46.10 43.26,46.04 43.26,46.04
             43.26,46.04 43.31,46.04 43.31,46.04 Z
           M 46.10,46.04
           C 46.10,46.04 46.10,46.10 46.10,46.10
             46.10,46.10 46.04,46.04 46.04,46.04
             46.04,46.04 46.10,46.04 46.10,46.04 Z
           M 46.59,46.04
           C 46.59,46.04 46.59,46.10 46.59,46.10
             46.59,46.10 46.53,46.04 46.53,46.04
             46.53,46.04 46.59,46.04 46.59,46.04 Z
           M 23.38,46.10
           C 23.38,46.10 23.38,46.15 23.38,46.15
             23.38,46.15 23.32,46.10 23.32,46.10
             23.32,46.10 23.38,46.10 23.38,46.10 Z
           M 44.18,46.10
           C 44.18,46.10 44.18,46.15 44.18,46.15
             44.18,46.15 44.13,46.10 44.13,46.10
             44.13,46.10 44.18,46.10 44.18,46.10 Z
           M 45.82,46.10
           C 45.82,46.10 45.82,46.15 45.82,46.15
             45.82,46.15 45.77,46.10 45.77,46.10
             45.77,46.10 45.82,46.10 45.82,46.10 Z
           M 46.53,46.10
           C 46.53,46.10 46.53,46.15 46.53,46.15
             46.53,46.15 46.48,46.10 46.48,46.10
             46.48,46.10 46.53,46.10 46.53,46.10 Z
           M 46.37,46.26
           C 46.37,46.26 46.37,46.31 46.37,46.31
             46.37,46.31 46.31,46.26 46.31,46.26
             46.31,46.26 46.37,46.26 46.37,46.26 Z
           M 36.16,46.64
           C 36.16,46.64 36.16,46.70 36.16,46.70
             36.16,46.70 36.10,46.64 36.10,46.64
             36.10,46.64 36.16,46.64 36.16,46.64 Z
           M 41.94,46.64
           C 41.94,46.64 41.94,46.70 41.94,46.70
             41.94,46.70 41.89,46.64 41.89,46.64
             41.89,46.64 41.94,46.64 41.94,46.64 Z
           M 31.90,46.75
           C 31.90,46.75 31.90,46.81 31.90,46.81
             31.90,46.81 31.84,46.75 31.84,46.75
             31.84,46.75 31.90,46.75 31.90,46.75 Z
           M 21.19,46.81
           C 21.19,46.81 21.19,46.86 21.19,46.86
             21.19,46.86 21.14,46.81 21.14,46.81
             21.14,46.81 21.19,46.81 21.19,46.81 Z
           M 27.53,46.86
           C 27.53,46.86 27.53,46.91 27.53,46.91
             27.53,46.91 27.47,46.86 27.47,46.86
             27.47,46.86 27.53,46.86 27.53,46.86 Z
           M 44.24,46.86
           C 44.24,46.86 44.24,46.91 44.24,46.91
             44.24,46.91 44.18,46.86 44.18,46.86
             44.18,46.86 44.24,46.86 44.24,46.86 Z
           M 53.36,46.97
           C 53.36,46.97 53.36,47.02 53.36,47.02
             53.36,47.02 53.30,46.97 53.30,46.97
             53.30,46.97 53.36,46.97 53.36,46.97 Z
           M 59.59,46.97
           C 59.59,46.97 59.59,47.02 59.59,47.02
             59.59,47.02 59.53,46.97 59.53,46.97
             59.53,46.97 59.59,46.97 59.59,46.97 Z
           M 37.41,47.02
           C 37.41,47.02 37.41,47.08 37.41,47.08
             37.41,47.08 37.36,47.02 37.36,47.02
             37.36,47.02 37.41,47.02 37.41,47.02 Z
           M 21.96,47.30
           C 21.96,47.30 21.96,47.35 21.96,47.35
             21.96,47.35 21.90,47.30 21.90,47.30
             21.90,47.30 21.96,47.30 21.96,47.30 Z
           M 37.68,47.46
           C 37.68,47.46 37.68,47.52 37.68,47.52
             37.68,47.52 37.63,47.46 37.63,47.46
             37.63,47.46 37.68,47.46 37.68,47.46 Z
           M 37.96,47.95
           C 37.96,47.95 37.96,48.01 37.96,48.01
             37.96,48.01 37.90,47.95 37.90,47.95
             37.90,47.95 37.96,47.95 37.96,47.95 Z
           M 37.08,48.12
           C 37.08,48.12 37.08,48.17 37.08,48.17
             37.08,48.17 37.03,48.12 37.03,48.12
             37.03,48.12 37.08,48.12 37.08,48.12 Z
           M 39.71,48.12
           C 39.71,48.12 39.71,48.17 39.71,48.17
             39.71,48.17 39.65,48.12 39.65,48.12
             39.65,48.12 39.71,48.12 39.71,48.12 Z
           M 47.95,48.28
           C 47.95,48.28 48.40,48.80 48.40,48.80
             48.57,48.96 48.71,48.95 49.04,49.23
             49.04,49.23 49.76,49.96 49.76,49.96
             50.06,50.26 50.08,50.19 50.48,50.63
             50.62,50.79 51.16,51.20 51.18,51.30
             51.22,51.38 51.18,51.66 51.18,51.78
             51.16,52.37 51.34,52.57 51.30,52.75
             51.23,53.01 50.39,53.34 50.14,53.47
             49.79,53.63 49.84,53.79 49.53,54.00
             49.13,54.28 48.22,54.73 47.95,55.05
             47.86,55.04 47.76,55.04 47.68,55.00
             47.68,55.00 46.59,54.20 46.59,54.20
             46.22,54.01 46.17,54.11 45.88,54.11
             45.88,54.11 45.49,54.07 45.49,54.07
             45.17,54.06 44.75,54.16 44.46,54.29
             44.46,54.29 42.17,50.63 42.17,50.63
             42.17,50.63 41.18,48.99 41.18,48.99
             41.70,49.22 41.82,49.55 42.93,49.91
             43.35,50.05 43.80,50.10 44.24,50.13
             44.41,50.14 44.51,50.11 44.68,50.09
             45.68,49.97 46.13,49.73 46.97,49.20
             46.97,49.20 47.35,48.96 47.35,48.96
             47.63,48.74 47.70,48.50 47.95,48.28 Z
           M 42.05,48.44
           C 42.05,48.44 42.05,48.50 42.05,48.50
             42.05,48.50 42.00,48.44 42.00,48.44
             42.00,48.44 42.05,48.44 42.05,48.44 Z
           M 42.60,48.83
           C 42.60,48.83 42.60,48.88 42.60,48.88
             42.60,48.88 42.55,48.83 42.55,48.83
             42.55,48.83 42.60,48.83 42.60,48.83 Z
           M 42.44,48.88
           C 42.44,48.88 42.44,48.94 42.44,48.94
             42.44,48.94 42.38,48.88 42.38,48.88
             42.38,48.88 42.44,48.88 42.44,48.88 Z
           M 41.13,49.04
           C 41.13,49.04 41.13,49.10 41.13,49.10
             41.13,49.10 41.07,49.04 41.07,49.04
             41.07,49.04 41.13,49.04 41.13,49.04 Z
           M 42.82,49.04
           C 42.82,49.04 42.82,49.10 42.82,49.10
             42.82,49.10 42.76,49.04 42.76,49.04
             42.76,49.04 42.82,49.04 42.82,49.04 Z
           M 60.95,49.10
           C 60.95,49.10 60.95,49.15 60.95,49.15
             60.95,49.15 60.90,49.10 60.90,49.10
             60.90,49.10 60.95,49.10 60.95,49.10 Z
           M 43.15,49.21
           C 43.15,49.21 43.15,49.26 43.15,49.26
             43.15,49.26 43.09,49.21 43.09,49.21
             43.09,49.21 43.15,49.21 43.15,49.21 Z
           M 42.87,49.37
           C 42.87,49.37 42.87,49.43 42.87,49.43
             42.87,49.43 42.82,49.37 42.82,49.37
             42.82,49.37 42.87,49.37 42.87,49.37 Z
           M 61.39,49.43
           C 61.39,49.43 61.39,49.48 61.39,49.48
             61.39,49.48 61.33,49.43 61.33,49.43
             61.33,49.43 61.39,49.43 61.39,49.43 Z
           M 39.00,49.75
           C 39.00,49.75 39.00,49.81 39.00,49.81
             39.00,49.81 38.94,49.75 38.94,49.75
             38.94,49.75 39.00,49.75 39.00,49.75 Z
           M 61.99,49.86
           C 61.99,49.86 61.99,49.92 61.99,49.92
             61.99,49.92 61.93,49.86 61.93,49.86
             61.93,49.86 61.99,49.86 61.99,49.86 Z
           M 40.85,49.97
           C 40.85,49.97 40.85,50.03 40.85,50.03
             40.85,50.03 40.80,49.97 40.80,49.97
             40.80,49.97 40.85,49.97 40.85,49.97 Z
           M 51.61,50.30
           C 51.61,50.30 51.61,50.36 51.61,50.36
             51.61,50.36 51.56,50.30 51.56,50.30
             51.56,50.30 51.61,50.30 51.61,50.30 Z
           M 63.63,56.31
           C 63.63,56.31 62.21,58.33 62.21,58.33
             61.84,58.26 60.93,57.58 60.57,57.33
             60.36,57.20 60.16,56.99 59.92,56.96
             59.91,56.87 59.90,56.84 59.92,56.75
             59.92,56.75 60.74,55.49 60.74,55.49
             60.74,55.49 63.20,51.83 63.20,51.83
             63.20,51.83 63.90,50.79 63.90,50.79
             63.97,50.68 64.13,50.41 64.25,50.36
             64.41,50.29 64.69,50.52 64.83,50.61
             64.83,50.61 66.05,51.43 66.05,51.43
             66.18,51.52 66.44,51.67 66.47,51.83
             66.49,51.98 66.35,52.16 66.27,52.27
             66.27,52.27 65.76,53.03 65.76,53.03
             65.76,53.03 64.23,55.33 64.23,55.33
             64.08,55.56 63.67,56.06 63.63,56.31 Z
           M 64.61,50.30
           C 64.61,50.30 64.61,50.36 64.61,50.36
             64.61,50.36 64.56,50.30 64.56,50.30
             64.56,50.30 64.61,50.30 64.61,50.30 Z
           M 41.07,50.36
           C 41.07,50.36 41.07,50.41 41.07,50.41
             41.07,50.41 41.02,50.36 41.02,50.36
             41.02,50.36 41.07,50.36 41.07,50.36 Z
           M 62.64,50.36
           C 62.64,50.36 62.64,50.41 62.64,50.41
             62.64,50.41 62.59,50.36 62.59,50.36
             62.59,50.36 62.64,50.36 62.64,50.36 Z
           M 64.66,50.36
           C 64.66,50.36 64.66,50.41 64.66,50.41
             64.66,50.41 64.61,50.36 64.61,50.36
             64.61,50.36 64.66,50.36 64.66,50.36 Z
           M 62.92,50.52
           C 62.92,50.52 62.92,50.57 62.92,50.57
             62.92,50.57 62.86,50.52 62.86,50.52
             62.86,50.52 62.92,50.52 62.92,50.52 Z
           M 62.92,50.85
           C 62.92,50.85 62.92,50.90 62.92,50.90
             62.92,50.90 62.86,50.85 62.86,50.85
             62.86,50.85 62.92,50.85 62.92,50.85 Z
           M 39.81,50.90
           C 39.81,50.90 39.81,50.96 39.81,50.96
             39.81,50.96 39.76,50.90 39.76,50.90
             39.76,50.90 39.81,50.90 39.81,50.90 Z
           M 65.98,51.23
           C 65.98,51.23 65.98,51.28 65.98,51.28
             65.98,51.28 65.92,51.23 65.92,51.23
             65.92,51.23 65.98,51.23 65.98,51.23 Z
           M 42.49,51.28
           C 42.49,51.28 42.49,51.34 42.49,51.34
             42.49,51.34 42.44,51.28 42.44,51.28
             42.44,51.28 42.49,51.28 42.49,51.28 Z
           M 40.14,51.45
           C 40.14,51.45 40.14,51.50 40.14,51.50
             40.14,51.50 40.09,51.45 40.09,51.45
             40.09,51.45 40.14,51.45 40.14,51.45 Z
           M 63.30,51.61
           C 63.30,51.61 63.30,51.67 63.30,51.67
             63.30,51.67 63.24,51.61 63.24,51.61
             63.24,51.61 63.30,51.61 63.30,51.61 Z
           M 66.52,51.61
           C 66.52,51.61 66.52,51.67 66.52,51.67
             66.52,51.67 66.47,51.61 66.47,51.61
             66.47,51.61 66.52,51.61 66.52,51.61 Z
           M 52.27,52.05
           C 52.27,52.05 52.27,52.10 52.27,52.10
             52.27,52.10 52.21,52.05 52.21,52.05
             52.21,52.05 52.27,52.05 52.27,52.05 Z
           M 42.27,52.32
           C 42.27,52.32 42.27,52.38 42.27,52.38
             42.27,52.38 42.22,52.32 42.22,52.32
             42.22,52.32 42.27,52.32 42.27,52.32 Z
           M 40.96,52.76
           C 40.96,52.76 40.96,52.81 40.96,52.81
             40.96,52.81 40.91,52.76 40.91,52.76
             40.91,52.76 40.96,52.76 40.96,52.76 Z
           M 61.50,52.76
           C 61.50,52.76 61.50,52.81 61.50,52.81
             61.50,52.81 61.44,52.76 61.44,52.76
             61.44,52.76 61.50,52.76 61.50,52.76 Z
           M 62.43,52.92
           C 62.43,52.92 62.43,52.98 62.43,52.98
             62.43,52.98 62.37,52.92 62.37,52.92
             62.37,52.92 62.43,52.92 62.43,52.92 Z
           M 62.32,53.09
           C 62.32,53.09 62.32,53.14 62.32,53.14
             62.32,53.14 62.26,53.09 62.26,53.09
             62.26,53.09 62.32,53.09 62.32,53.09 Z
           M 42.93,53.36
           C 42.93,53.36 42.93,53.41 42.93,53.41
             42.93,53.41 42.87,53.36 42.87,53.36
             42.87,53.36 42.93,53.36 42.93,53.36 Z
           M 43.97,53.63
           C 43.97,53.63 43.97,53.69 43.97,53.69
             43.97,53.69 43.91,53.63 43.91,53.63
             43.91,53.63 43.97,53.63 43.97,53.63 Z
           M 56.64,53.69
           C 56.64,53.69 56.64,53.74 56.64,53.74
             56.64,53.74 56.58,53.69 56.58,53.69
             56.58,53.69 56.64,53.69 56.64,53.69 Z
           M 43.20,53.80
           C 43.20,53.80 43.20,53.85 43.20,53.85
             43.20,53.85 43.15,53.80 43.15,53.80
             43.15,53.80 43.20,53.80 43.20,53.80 Z
           M 56.85,53.91
           C 56.85,53.91 56.85,53.96 56.85,53.96
             56.85,53.96 56.80,53.91 56.80,53.91
             56.80,53.91 56.85,53.91 56.85,53.91 Z
           M 43.47,54.07
           C 43.47,54.07 43.47,54.12 43.47,54.12
             43.47,54.12 43.42,54.07 43.42,54.07
             43.42,54.07 43.47,54.07 43.47,54.07 Z
           M 57.95,54.51
           C 57.95,54.51 57.95,54.56 57.95,54.56
             57.95,54.56 57.89,54.51 57.89,54.51
             57.89,54.51 57.95,54.51 57.95,54.51 Z
           M 64.83,54.51
           C 64.83,54.51 64.83,54.56 64.83,54.56
             64.83,54.56 64.77,54.51 64.77,54.51
             64.77,54.51 64.83,54.51 64.83,54.51 Z
           M 27.58,54.62
           C 27.58,54.62 27.58,54.67 27.58,54.67
             27.58,54.67 27.53,54.62 27.53,54.62
             27.53,54.62 27.58,54.62 27.58,54.62 Z
           M 26.98,54.67
           C 26.98,54.67 26.98,54.72 26.98,54.72
             26.98,54.72 26.93,54.67 26.93,54.67
             26.93,54.67 26.98,54.67 26.98,54.67 Z
           M 41.18,54.67
           C 41.18,54.67 41.18,54.72 41.18,54.72
             41.18,54.72 41.13,54.67 41.13,54.67
             41.13,54.67 41.18,54.67 41.18,54.67 Z
           M 58.33,54.67
           C 58.33,54.67 58.33,54.72 58.33,54.72
             58.33,54.72 58.27,54.67 58.27,54.67
             58.27,54.67 58.33,54.67 58.33,54.67 Z
           M 27.03,54.72
           C 27.03,54.72 27.03,54.78 27.03,54.78
             27.03,54.78 26.98,54.72 26.98,54.72
             26.98,54.72 27.03,54.72 27.03,54.72 Z
           M 58.17,54.72
           C 58.17,54.72 58.17,54.78 58.17,54.78
             58.17,54.78 58.11,54.72 58.11,54.72
             58.11,54.72 58.17,54.72 58.17,54.72 Z
           M 27.14,54.83
           C 27.14,54.83 27.14,54.89 27.14,54.89
             27.14,54.89 27.09,54.83 27.09,54.83
             27.09,54.83 27.14,54.83 27.14,54.83 Z
           M 43.91,54.94
           C 43.91,54.94 43.91,55.00 43.91,55.00
             43.91,55.00 43.86,54.94 43.86,54.94
             43.86,54.94 43.91,54.94 43.91,54.94 Z
           M 27.31,55.00
           C 27.31,55.00 27.31,55.05 27.31,55.05
             27.31,55.05 27.25,55.00 27.25,55.00
             27.25,55.00 27.31,55.00 27.31,55.00 Z
           M 59.09,55.11
           C 59.09,55.11 59.09,55.16 59.09,55.16
             59.09,55.16 59.04,55.11 59.04,55.11
             59.04,55.11 59.09,55.11 59.09,55.11 Z
           M 59.48,55.38
           C 59.48,55.38 59.48,55.43 59.48,55.43
             59.48,55.43 59.42,55.38 59.42,55.38
             59.42,55.38 59.48,55.38 59.48,55.38 Z
           M 42.65,55.43
           C 42.65,55.43 42.65,55.49 42.65,55.49
             42.65,55.49 42.60,55.43 42.60,55.43
             42.60,55.43 42.65,55.43 42.65,55.43 Z
           M 60.62,55.60
           C 60.62,55.60 60.62,55.65 60.62,55.65
             60.62,55.65 60.57,55.60 60.57,55.60
             60.57,55.60 60.62,55.60 60.62,55.60 Z
           M 64.06,55.65
           C 64.06,55.65 64.06,55.71 64.06,55.71
             64.06,55.71 64.01,55.65 64.01,55.65
             64.01,55.65 64.06,55.65 64.06,55.65 Z
           M 48.12,55.71
           C 48.12,55.71 48.12,55.76 48.12,55.76
             48.12,55.76 48.06,55.71 48.06,55.71
             48.06,55.71 48.12,55.71 48.12,55.71 Z
           M 42.93,55.87
           C 42.93,55.87 42.93,55.93 42.93,55.93
             42.93,55.93 42.87,55.87 42.87,55.87
             42.87,55.87 42.93,55.87 42.93,55.87 Z
           M 43.31,56.53
           C 43.31,56.53 43.31,56.58 43.31,56.58
             43.31,56.58 43.26,56.53 43.26,56.53
             43.26,56.53 43.31,56.53 43.31,56.53 Z
           M 63.24,56.85
           C 63.24,56.85 63.24,56.91 63.24,56.91
             63.24,56.91 63.19,56.85 63.19,56.85
             63.19,56.85 63.24,56.85 63.24,56.85 Z
           M 60.84,57.51
           C 60.84,57.51 60.84,57.56 60.84,57.56
             60.84,57.56 60.79,57.51 60.79,57.51
             60.79,57.51 60.84,57.51 60.84,57.51 Z
           M 49.37,57.84
           C 49.37,57.84 49.37,57.89 49.37,57.89
             49.37,57.89 49.32,57.84 49.32,57.84
             49.32,57.84 49.37,57.84 49.37,57.84 Z
           M 44.62,58.71
           C 44.62,58.71 44.62,58.77 44.62,58.77
             44.62,58.77 44.57,58.71 44.57,58.71
             44.57,58.71 44.62,58.71 44.62,58.71 Z
           M 45.17,59.48
           C 45.17,59.48 45.17,59.53 45.17,59.53
             45.17,59.53 45.11,59.48 45.11,59.48
             45.11,59.48 45.17,59.48 45.17,59.48 Z"
                    />
                  </svg>
                </div>
                <div class="texto">{{traducciones.find((item)=>item.item == 'Solicitar cuenta').value}}</div>
              </div>
            </div>
          </div>
          <div class="row" v-if="solicitarCuenta" id="camarero-pago">
            <div class="col-sm-6">
              <div
                class="llamar-container pagar-camarero"
                @click="showSolicitarCuenta(carrito)"
                data-bs-dismiss="modal"
              >
                <div>
                  <svg
                    version="1.1"
                    width="71px"
                    height="71px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 480.718 480.718"
                    style="enable-background: new 0 0 480.718 480.718"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <g>
                          <path
                            style="fill: #3bb54a"
                            d="M471.804,181.845v180.767c-0.285,15.954-13.301,28.737-29.257,28.735H97.73
                      c-15.87,0-28.735-12.865-28.735-28.735V328.13h313.992c15.954-0.285,28.737-13.301,28.735-29.257V152.588h30.824
                      C458.705,152.588,471.804,165.686,471.804,181.845z"
                          />
                          <path
                            style="fill: #3bb54a"
                            d="M411.722,152.588v146.286c0.003,15.956-12.781,28.972-28.735,29.257H38.171
                      c-16.158,0-29.257-13.099-29.257-29.257V118.106c0.285-15.954,13.301-28.737,29.257-28.735h344.816
                      c15.87,0,28.735,12.865,28.735,28.735V152.588z M380.375,275.886c0-11.542-9.356-20.898-20.898-20.898
                      c-11.542,0-20.898,9.356-20.898,20.898s9.356,20.898,20.898,20.898C371.019,296.784,380.375,287.427,380.375,275.886
                      L380.375,275.886L380.375,275.886z M303.575,208.49c-0.001-51.649-41.871-93.518-93.52-93.517s-93.518,41.871-93.517,93.52
                      c0.001,51.649,41.871,93.518,93.52,93.517c0.174,0,0.347,0,0.521-0.001c51.36,0.001,92.997-41.634,92.997-92.994
                      C303.577,208.839,303.576,208.664,303.575,208.49z M82.579,140.049c0-11.542-9.356-20.898-20.898-20.898
                      s-20.898,9.356-20.898,20.898s9.356,20.898,20.898,20.898S82.579,151.59,82.579,140.049z"
                          />
                        </g>
                        <g>
                          <circle
                            style="fill: #87e680"
                            cx="359.477"
                            cy="275.886"
                            r="20.898"
                          />
                          <circle
                            style="fill: #87e680"
                            cx="210.579"
                            cy="208.49"
                            r="92.996"
                          />
                          <circle
                            style="fill: #87e680"
                            cx="61.681"
                            cy="140.049"
                            r="20.898"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          style="fill: #083863"
                          d="M210.579,312.457c-57.419,0.289-104.2-46.025-104.488-103.444s46.025-104.2,103.444-104.488
                    c57.419-0.289,104.2,46.025,104.488,103.444c0.001,0.174,0.001,0.347,0.001,0.521
                    C314.025,265.706,267.795,312.169,210.579,312.457z M210.579,125.943c-45.877-0.288-83.301,36.67-83.589,82.547
                    s36.67,83.301,82.547,83.589c45.877,0.288,83.301-36.67,83.589-82.547c0.002-0.347,0.002-0.694,0-1.042
                    C292.841,163.019,256.05,126.228,210.579,125.943z"
                        />
                        <path
                          style="fill: #083863"
                          d="M442.547,141.094h-19.331v-22.988c-0.56-21.896-18.327-39.432-40.229-39.706H38.171
                    c-21.35,0.281-38.43,17.816-38.15,39.166c0.002,0.18,0.006,0.36,0.011,0.54v180.767c-0.873,21.623,15.948,39.859,37.571,40.732
                    c0.189,0.008,0.378,0.014,0.567,0.019h19.331v22.988c0.56,21.896,18.327,39.432,40.229,39.706h344.816
                    c21.35-0.281,38.43-17.816,38.15-39.166c-0.002-0.18-0.006-0.36-0.011-0.54V181.845c0.873-21.623-15.948-39.859-37.571-40.732
                    C442.925,141.105,442.736,141.099,442.547,141.094z M20.93,298.873V118.106c-0.581-9.793,6.887-18.203,16.68-18.784
                    c0.187-0.011,0.373-0.019,0.56-0.024h344.816c10.367,0.259,18.788,8.452,19.331,18.808v180.767
                    c-0.259,10.655-8.686,19.31-19.331,19.853H38.171C27.722,318.726,20.93,309.322,20.93,298.873z M459.788,362.612
                    c0.581,9.793-6.887,18.203-16.68,18.784c-0.187,0.011-0.374,0.019-0.56,0.024H97.73c-10.367-0.259-18.788-8.452-19.331-18.808
                    v-22.988h304.588c22.184-0.56,39.955-18.561,40.229-40.751V161.992h19.331c10.449,0,17.241,9.404,17.241,19.853V362.612z"
                        />
                        <path
                          style="fill: #083863"
                          d="M61.681,171.396c-17.312,0-31.347-14.035-31.347-31.347c0-17.312,14.034-31.347,31.347-31.347
                    s31.347,14.034,31.347,31.347l0,0C93.028,157.361,78.994,171.396,61.681,171.396z M61.681,129.6
                    c-5.771,0-10.449,4.678-10.449,10.449c0,5.771,4.678,10.449,10.449,10.449s10.449-4.678,10.449-10.449l0,0
                    C72.13,134.278,67.452,129.6,61.681,129.6z"
                        />
                        <path
                          style="fill: #083863"
                          d="M359.477,307.232c-17.312,0-31.347-14.035-31.347-31.347s14.035-31.347,31.347-31.347
                    c17.312,0,31.347,14.034,31.347,31.347l0,0C390.824,293.198,376.79,307.232,359.477,307.232z M359.477,265.437
                    c-5.771,0-10.449,4.678-10.449,10.449c0,5.771,4.678,10.449,10.449,10.449c5.771,0,10.449-4.678,10.449-10.449
                    C369.926,270.115,365.248,265.437,359.477,265.437z"
                        />
                        <path
                          style="fill: #083863"
                          d=""
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="texto">{{traducciones.find((item)=>item.item == 'EnEfectivo').value}}</div>
              </div>
            </div>
            <div v-if="tienetarjeta()" class="col-sm-6">
              <div
                class="llamar-container pagar-camarero"
                @click="showSolicitarCuenta(carrito)"
                data-bs-dismiss="modal"
              >
                <div >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    fill="rgb(74, 79, 75)"
                    viewBox="0 0 875 875"
                    width="71px"
                    height="71px"
                  >
                    <g id="surface1">
                      <path
                        d="M 454.171875 370.855469 C 424.148438 337.378906 426.09375 286.128906 458.554688 255.007812 C 491.023438 223.90625 542.324219 224.148438 574.488281 255.578125 L 717.609375 398.71875 C 718.222656 396.054688 718.601562 393.351562 718.75 390.625 L 718.75 146.59375 L 718.601562 146.234375 L 718.75 146.234375 L 718.75 46.875 C 718.75 20.992188 697.757812 0 671.875 0 L 46.875 0 C 20.992188 0 0 20.992188 0 46.875 L 0 390.625 C 0 416.507812 20.992188 437.5 46.875 437.5 L 515.265625 437.5 Z M 625 46.875 L 656.25 46.875 L 656.25 93.75 L 625 93.75 Z M 562.5 46.875 L 593.75 46.875 L 593.75 93.75 L 562.5 93.75 Z M 500 46.875 L 531.25 46.875 L 531.25 93.75 L 500 93.75 Z M 437.5 46.875 L 468.75 46.875 L 468.75 93.75 L 437.5 93.75 Z M 31.25 146.875 L 31.25 71.875 C 31.25 49.4375 49.4375 31.25 71.875 31.25 L 146.875 31.25 C 169.3125 31.25 187.5 49.4375 187.5 71.875 L 187.5 146.875 C 187.5 169.3125 169.3125 187.5 146.875 187.5 L 71.875 187.5 C 49.4375 187.5 31.25 169.3125 31.25 146.875 Z M 109.375 359.375 L 46.875 359.375 L 46.875 328.125 L 109.375 328.125 Z M 109.375 265.625 L 46.875 265.625 L 46.875 234.375 L 109.375 234.375 Z M 203.125 265.625 L 140.625 265.625 L 140.625 234.375 L 203.125 234.375 Z M 296.875 265.625 L 234.375 265.625 L 234.375 234.375 L 296.875 234.375 Z M 390.625 359.375 L 328.125 359.375 L 328.125 328.125 L 390.625 328.125 Z M 390.625 265.625 L 328.125 265.625 L 328.125 234.375 L 390.625 234.375 Z M 390.625 265.625 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 72.625 468.75 L 89.109375 511.527344 L 200.171875 468.75 Z M 72.625 468.75 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 146.875 156.25 C 152.054688 156.25 156.25 152.050781 156.25 146.875 L 156.25 125 L 125 125 L 125 93.75 L 156.25 93.75 L 156.25 71.875 C 156.25 66.699219 152.054688 62.5 146.875 62.5 L 71.875 62.5 C 66.699219 62.5 62.5 66.699219 62.5 71.875 L 62.5 93.75 L 93.75 93.75 L 93.75 125 L 62.5 125 L 62.5 146.875 C 62.5 152.050781 66.699219 156.25 71.875 156.25 Z M 146.875 156.25 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 796.53125 386.6875 C 801.632812 375.3125 801.949219 362.363281 797.421875 350.757812 L 750 227.726562 L 750 390.625 C 749.976562 401.375 747.734375 412.023438 743.40625 421.875 L 770.511719 411.425781 C 782.140625 406.984375 791.507812 398.078125 796.53125 386.6875 Z M 796.53125 386.6875 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 824.3125 252.96875 L 773.6875 202.246094 L 826.5625 339.574219 C 842.109375 379.859375 822.046875 425.109375 781.742188 440.625 L 765.71875 446.875 L 823.535156 504.6875 L 801.441406 526.78125 L 552.464844 277.6875 C 532.417969 257.714844 500.09375 257.390625 479.644531 276.972656 C 459.203125 296.539062 458.128906 328.859375 477.222656 349.746094 L 605.347656 489.445312 C 609.929688 494.445312 610.765625 501.855469 607.390625 507.753906 C 571.710938 570.097656 573.234375 647.027344 611.347656 707.921875 L 618.125 718.75 L 875 718.75 L 875 374.863281 C 874.875 329.125 856.648438 285.308594 824.3125 252.96875 Z M 824.3125 252.96875 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 671.875 750 L 625 750 L 625 875 L 875 875 L 875 750 Z M 687.5 812.5 L 656.25 812.5 L 656.25 781.25 L 687.5 781.25 Z M 687.5 812.5 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 286.828125 468.75 L 286.828125 468.84375 L 100.34375 540.625 L 126.703125 609.105469 C 136.011719 633.273438 163.140625 645.3125 187.296875 635.996094 L 564.203125 490.828125 L 543.90625 468.75 Z M 286.828125 468.75 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                    </g>
                  </svg>
                </div>
                <div class="texto">{{traducciones.find((item)=>item.item == 'PagarConTarjeta').value}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import * as bootstrap from "bootstrap";
import { API_URL } from "../../utils/constants";
export default {
methods: {
 
   tienetarjeta: function () {
   var encontrado = false;
   for(let i = 0; i < this.restaurante.formaspago.length; i++){ 
        if ( this.restaurante.formaspago[i].nombre.includes('card') || this.restaurante.formaspago[i].nombre.includes('tarjeta'))
            {
            encontrado=true;
            break;
            }
        } 
    
    if (encontrado == false)
    {
        for(let i = 0; i < this.restaurante.formaspago.length; i++){ 
        if ( this.restaurante.formaspago[i].id == 1 && this.restaurante.formaspago[i].nombre.includes('card'))
            {
            encontrado=true;
            break;
            }
        } 
    }    
    return encontrado;
    }
  },
  setup() {
    const store = useStore();
    const dialog = inject("$swal");
    const solicitarCuenta = ref(false);
const modulos = computed(() => store.state.restaurante.restaurante.modulos);
    onMounted(() => {
      let myModal = new bootstrap.Modal(
        document.getElementById("llamarCamarero"),
        {
          backdrop: "true",
        }
      );
      
      myModal.show();

      document
        .getElementById("llamarCamarero")
        .addEventListener("hidden.bs.modal", function (event) {
          backdrop();
        });
    });

    const showSolicitarCuenta = (carrito) => {
    const $ = require('jquery');
       window.$ = $;
      if (carrito.length === 0) {
        dialog({
          position: "center",
          icon: "error",
          html: traducciones.find((item)=>item.item == 'No tienes ningun pedido pendiente').value,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
      
      
       let puesto= JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.puesto;
      
       $.getJSON( `${API_URL}/llamar?action=BILL&puesto=`+puesto, function( data ) {
           if (data.success)
              dialog({
                      position: "center",
                      icon: "success",
                      html: traducciones.find((item)=>item.item == 'Tu cuenta llegara a tu mesa en un momento').value,
                      showConfirmButton: false,
                      timer: 2000,
                    });
            
            });
        
      }
    };

    const solicitarCamarero = () => {
       const $ = require('jquery');
       window.$ = $;
      
       let puesto= JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.puesto;
      
       $.getJSON( `${API_URL}/llamar?action=CALL&puesto=`+puesto, function( data ) {
           if (data.success)
               dialog({
                    position: "center",
                    icon: "success",
                    title: "El camarero ha sido llamado",
                    showConfirmButton: false,
                    timer: 2000,
                  });
            
            });
    };

    const solicitarCuentaHandler = () => {
      solicitarCuenta.value = true;
    };
    var carrito = null;
      if ((store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Pedido') !== undefined) || (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'AutoPedidos') !== undefined) || (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Comandero') !== undefined))
      
        carrito = computed(() => store.state.carrito.carrito);
    else
         carrito =  computed(() => store.state.productos.listaDeseo);
    const backdrop = () => {
      store.commit("menu/mostrarCamarero", false);
    };
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale)
  const restaurante = computed(() => store.state.restaurante.restaurante);
    return {
      solicitarCuenta,
      restaurante,
      solicitarCuentaHandler,
      showSolicitarCuenta,
      solicitarCamarero,
      carrito,
      traducciones
    };
  },
};
</script>

<style lang="scss" scoped>
.llamar-container {
  background: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 25px;
  transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  cursor: pointer;
  text-align: center;

  @media (max-width: 600px) {
    margin-bottom: 25px;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 30px 60px;
    transform: scale(1.05, 1.05);
  }

  .texto {
    margin-top: 25px;
    font-size: 18px;
    font-weight: bold;
  }
}

#camarero-opciones {
  display: flex;
}

.cerrar {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 12px;
}

.modal-body {
  padding: 60px 20px;
}
</style>
