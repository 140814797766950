<template>
  <Layout @filtra="handleCustomChange">
 
    <div class="card-container mobile-m">
      <Card
        v-for="categoria in filterItems(categorias)"
        :key="categoria.id"
        :categoria="categoria"
      />
    </div>
    
  </Layout>

</template>

<script>
import { useStore } from "vuex";

// @ is an alias to /src
import Card from "@/components/card/Card.vue";
import Layout from "@/layout/Layout.vue";
import { computed } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
export default {
  name: "Home",
  components: {
    Card,
    Layout,
  },
  emits: ["filtra"],
  setup() {

    const store = useStore();
    const searchQuery = ref("");
    const categorias = computed(
      () => store.getters["categorias/OrdenarPorIdioma"]
    );

    return { categorias,searchQuery };

  },
    methods: {
    handleCustomChange(data) {
    console.log('emit:');
            this.searchQuery = data;
            },
    filterItems: function(cat) {
      var app = this;
      if (app.searchQuery)
      return cat.filter(function(item) {
      
        let regex = new RegExp('(' + app.searchQuery + ')', 'i');
        return item.nombre.match(regex);
      });
      else
        return cat;
    }
  },

};

</script>
