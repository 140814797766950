<template>
  <div class="navbar-categorias">
    <ul class="nav">
      <li v-for="categoria in categorias" :key="categoria.id" class="nav-item">
        <router-link
          
          class="nav-link"
          v-if="categoria.tienehijos===false"
          :to="{ name: 'Productos', params: { id: categoria.id } }"
          >{{ categoria.nombre }}</router-link
        >
        <router-link
          class="nav-link"
        v-else
          :to="{ name: 'Categorias', params: { id: categoria.id } }"
          >{{ categoria.nombre }}</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const categorias = computed(
      () => store.getters["categorias/OrdenarPorIdioma"]
    );

    return { categorias };
  },
};
</script>

<style lang="scss" scoped>
.navbar-categorias {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0 25px;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .nav {
    flex-wrap: nowrap;

    li {
      background: #f3f6f9;
      border-radius: 8px;
      margin-bottom: 4px;
      color: #3c505a;
      border: 1px solid #ebeff3;
      margin-right: 12px;
      font-size: 11px;
      flex-shrink: 0;

      &.active {
        background: #e6e6e6;
        font-weight: bold;
        /* border: 1px solid; */
      }

      a {
        color: #3c505a;
      }
    }
  }
}
</style>
