export const ordenes = {
    namespaced: true,
    state: {
        ordenes: []
    },
    actions: {
      
    },
    mutations: {
        agregarOrden(state, payload) {
            state.ordenes = [...state.ordenes, payload];
          },
    },
  };
  

