<template>
  <div class="container mt-3">
    <div class="alert alert-danger d-flex align-items-center" role="alert">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 16 16"
        class="me-3"
      >
        <path
          fill="#FF0017"
          d="M8.75 10.75a.75.75 0 10-1.5 0 .75.75 0 001.5 0zm-.258-5.84A.5.5 0 007.5 5v3.5l.008.09A.5.5 0 008.5 8.5V5l-.008-.09zM8 2a6 6 0 00-5.27 8.872l-.71 2.49a.5.5 0 00.638.612l2.338-.779A6 6 0 108 2zM3 8a5 5 0 112.325 4.225.5.5 0 00-.426-.052l-1.658.553.51-1.781a.5.5 0 00-.052-.393A4.975 4.975 0 013 8z"
        />
      </svg>
      <div>
        {{ msg }}
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  setup() {
    const store = useStore();

    const msg = computed(() => store.state.error.msg);

    return { msg };
  },
};
</script>

<style>
</style>