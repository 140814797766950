<template>
  <div class="collapse" id="alergenos-menu">
    <div class="alergenos-contenedor">
      <div class="row">
        <div class="col-12">
          <h5 id="alergenos">{{traducciones.find((item)=>item.item == 'Alergenos').value}}</h5>
          <ul>
            <li v-for="input in inputs">
              <label>
                <input v-if = "checked(input.name)" type="checkbox" :checked="input.name" :value="input.name" v-on:input="sendToParent" />
                <input v-else type="checkbox" :value="input.name" v-on:input="sendToParent" />
                 
                <div class="alergeno">
                  <img v-bind:src="`${input.icon}`" alt="" />
                  <span>{{input.name}}</span>
                </div>
              </label>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
  {{Camarero}}
</template>

<script>
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { onBeforeMount, computed } from "@vue/runtime-core";
export default {

setup() {
let searchAlergenos =  ref([]);
if (localStorage.searchAlergenos != undefined)
    searchAlergenos = ref(JSON.parse(localStorage.searchAlergenos));    
//let localStorage.searchAlergenos = computed => (this.searchAlergenos);
const inputs = [
   {
        name: 'Altramuces',
        icon:  require("@/assets/images/alergenos/altramuces.png"),
        active: true
   },
   {
        name: 'Apio',
        icon: require("@/assets/images/alergenos/apio.png"),
        active: false
   },
   {
        name: 'Cacahuete',
        icon: require("@/assets/images/alergenos/cacahuate.png"),
        active: false
   },
   {
        name: 'Crustaceo',
        icon: require("@/assets/images/alergenos/crustaceo.png"),
        active: false
   }
   ,
   {
        name: 'Dioxido',
        icon: require("@/assets/images/alergenos/dioxido.png"),
        active: false
   },
   {
        name: 'Frutos',
        icon: require("@/assets/images/alergenos/frutos.png"),
        active: false
   },
   {
        name: 'Gluten',
        icon: require("@/assets/images/alergenos/gluten.png"),
        active: false
   },
   {
        name: 'Huevo',
        icon: require("@/assets/images/alergenos/huevo.png"),
        active: false
   },
   {
        name: 'Lacteo',
        icon: require("@/assets/images/alergenos/lacteo.png"),
        active: false
   },
   {
        name: 'Moluscos',
        icon: require("@/assets/images/alergenos/moluscos.png"),
        active: false
   },
   {
        name: 'Mostaza',
        icon: require("@/assets/images/alergenos/mostaza.png"),
        active: false
   },
   {
        name: 'Pescado',
        icon: require("@/assets/images/alergenos/pescado.png"),
        active: false
   },
   {
        name: 'Proteina-leche',
        icon: require("@/assets/images/alergenos/proteina-leche.png"),
        active: false
   },
   {
        name: 'Sesamo',
        icon: require("@/assets/images/alergenos/sesamo.png"),
        active: false
   },
   {
        name: 'Soja',
        icon: require("@/assets/images/alergenos/soja.png"),
        active: false
   }
]
const store = useStore();
const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
   return { traducciones,searchAlergenos,inputs} 
  },
  computed: {
  
  },
  methods: {
            checked(alergeno) {
                if (localStorage.searchAlergenos)
                    {
                    
                    let regex = new RegExp('(' + alergeno + ')', 'i'); 
                    if (localStorage.searchAlergenos.match(regex) != undefined){
                        return true;
                        }
                    else
                        return false;
                    }
                else
                    return false;
            },
            sendToParent(data) {
             
            var index = this.searchAlergenos.indexOf(data.target.value);
            if (index>-1)
                this.searchAlergenos.splice(index,1)
            else
                this.searchAlergenos.push(data.target.value)
            localStorage.searchAlergenos = JSON.stringify(Array.from(this.searchAlergenos));
           console.log(localStorage.searchAlergenos);
            this.$emit('select', this.searchAlergenos);
            }
        },
}

</script>

<style>
</style>
