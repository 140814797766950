import { API_URL } from "../../utils/constants";
import { useRoute } from "vue-router";
import { ref } from "@vue/reactivity";
export const categorias = {
  namespaced: true,
  state: {
    categorias: [],
    searchQuery: ref(""),
  },
  actions: {
    async fetchCategorias({ commit, rootState }) {
      try {
        //Obtener sucursal id
        let sucursalId = rootState.restaurante.restaurante.id;
        let puesto= rootState.restaurante.restaurante.puesto;
        function check(array, key, value) {
            return array.some(object => object[key] === value);
        }
        
        let response = await fetch(`${API_URL}/getCategoriasProductos?puesto=${puesto}&sucursal=${sucursalId}`);
        let categorias = await response.json();
        categorias.categoriasproductos.forEach(function(element) {
            element.tienehijos = check(categorias.categoriasproductos,'categoriapadre',element.id.toString());
        
               
      
            
        });
        commit("addCategorias", categorias);
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
      }
    },
  },
  mutations: {
    addCategorias(state, payload) {
      state.categorias = payload.categoriasproductos;
    },
  },
  methods: {
  GetQuery: (state, _, rootState) => {
    return state.searchQuery;
   }, 
  },
  getters: {
  
    OrdenarPorIdioma: (state, _, rootState) => {
      if (state.categorias) {

         const find = state.categorias.filter(
          producto => producto.locale === rootState.idiomas.options.locale  && producto.categoriapadre === null
        );
        const activos = find.filter(
          activo => activo.activo === true
        )
           
        return activos;
      }
    },
    CategoriaPadre: (state, _, rootState) => {
      if (state.categorias) {
          const route = useRoute();
         const find = state.categorias.filter(
          producto => producto.locale === rootState.idiomas.options.locale && producto.categoriapadre === route.params.id
        );
        const activos = find.filter(
          activo => activo.activo === true
        )
        
        return activos;
      }
    },
  }
};
