import { API_URL } from "../../utils/constants";
export const menu = {
    namespaced: true,
    state: {
        llamarCamarero: false,
        mostrarNotificaciones: false,
        sideMenu:false,
        mostrarIdiomas: false,
        mostrarSidebar: false,
        mostrarDeseos: false,
        mostrarSugerencias: false,
        mostrarCesta: false,
        mostrarPromociones: false,
        resena: false,
    },
    actions: {
     
    },
    mutations: {
     traduce(state, payload) {
      const $ = require('jquery');
      window.$ = $;
            $(".menu").text(payload.find((item)=>item.item == 'Menu').value);
            $(".wishlist").text(payload.find((item)=>item.item == 'Wishlist').value);
            $(".sugerencias").text(payload.find((item)=>item.item == 'Sugerencias').value);
            $(".camarero").text(payload.find((item)=>item.item == 'Camarero').value);
            $(".review").text(payload.find((item)=>item.item == 'Review').value);
            $(".pagar").text(payload.find((item)=>item.item == 'Pagar').value);
            $(".download").text(payload.find((item)=>item.item == 'Login').value);
            //alert(localStorage.ivaincluido);
            if (localStorage.ivaincluido=="true")
                $(".producto-impuesto").text('('+payload.find((item)=>item.item == 'ImpuestosIncluidos').value+')');
            else
                $(".producto-impuesto").text('('+payload.find((item)=>item.item == 'ImpuestosNoIncluidos').value+')');            
            $("input[name=buscar]").attr('placeholder',payload.find((item)=>item.item == 'Buscar').value+'...');
            $("#alergenos").text(payload.find((item)=>item.item == 'Alergenos').value);
            $("#sortby").text(payload.find((item)=>item.item == 'SortBy').value);
            $("#sortbyalergeno").text(payload.find((item)=>item.item == 'SortBy').value+' '+payload.find((item)=>item.item == 'Alergeno').value);
            $("#start").text(payload.find((item)=>item.item == 'Inicio').value);
            $("#quienessomos").text(payload.find((item)=>item.item == 'Quienessomos').value);
            $("#reservas").text(payload.find((item)=>item.item == 'Reservas').value);
            $("#contacto").text(payload.find((item)=>item.item == 'Contacto').value);
            $("#promociones").text(payload.find((item)=>item.item == 'Promociones').value);
		    $("#legal").text(payload.find((item)=>item.item == 'Legal').value);
		    $("#login").text(payload.find((item)=>item.item == 'Login').value);
		    $("#logout").text(payload.find((item)=>item.item == 'Logout').value);
		    $(".ContactoLabel").text(payload.find((item)=>item.item == 'Contacto').value);
		    $("#ContactoTxt").text(payload.find((item)=>item.item == 'ContactoTxt').value);	
		    $("label[for='inputNombre']").text(payload.find((item)=>item.item == 'Nombre').value);
		    $("label[for='inputTelefono']").text(payload.find((item)=>item.item == 'Telefono').value);	
		    $("label[for='inputEmail']").text(payload.find((item)=>item.item == 'Email').value);
		    $("label[for='Mensaje']").text(payload.find((item)=>item.item == 'Mensaje').value);
            $("label[for='Peticion']").text(payload.find((item)=>item.item == 'Peticion').value);
            $("label[for='inputPersonas']").text(payload.find((item)=>item.item == 'Numero de personas').value);
            $(".dp__action dp__select").text(payload.find((item)=>item.item == 'seleccionar').value);
		    $("#enviarcomentario").text(payload.find((item)=>item.item == 'Enviar').value);
            $("#ReservasTxt").text(payload.find((item)=>item.item == 'ReservasTxt').value);
            $(".Reservas").text(payload.find((item)=>item.item == 'Reservas').value);
            $("#numerodepersonas option[id='una']").html("1 " + payload.find((item)=>item.item == 'Personas').value);
            $("#numerodepersonas option[id='dos']").html("2 " + payload.find((item)=>item.item == 'Personas').value);
            $("#numerodepersonas option[id='tres']").html("3 " + payload.find((item)=>item.item == 'Personas').value);
            $("#numerodepersonas option[id='cuatro']").html("4 " + payload.find((item)=>item.item == 'Personas').value);
            $("#numerodepersonas option[id='cinco']").html("5 " + payload.find((item)=>item.item == 'Personas').value);
            $("#numerodepersonas option[id='seis']").html("6 " + payload.find((item)=>item.item == 'Personas').value);
            $("#numerodepersonas option[id='selecciona']").html(payload.find((item)=>item.item == 'Seleccionar numero de personas').value);
             $("#telefono").text(payload.find((item)=>item.item == 'Telefono').value);
             $("#horarios").text(payload.find((item)=>item.item == 'Horarios').value);
             $("#redes").text(payload.find((item)=>item.item == 'Redes sociales').value);
      },
      mostrarCamarero(state, payload) {
          state.llamarCamarero = payload
      },
      mostrarDeseos(state, payload) {
          state.mostrarDeseos = payload
      },
      mostrarPromociones(state, payload) {
          state.mostrarPromociones = payload
      },
      mostrarSugerencias(state, payload) {
          state.mostrarSugerencias = payload
      },
      mostrarCesta(state, payload) {
          state.mostrarCesta = payload
      },
      mostrarResena(state, payload) {
          state.resena = payload
      },
      mostrarIdiomas(state, payload) {
          state.mostrarIdiomas = payload
      },
      mostrarSidebar(state, payload) {
          state.mostrarSidebar = payload
      },
      mostrarNotificaciones(state, payload) {
          state.mostrarNotificaciones = payload
      }
    },
     getters: {
        getModulo: state => nombre => {
      const result = state.restaurante.modulo.modulo.find(item => item.nombre === nombre) !== undefined;
      
      return result;
    },
    },

  };
