<template>
  <Layout>
    <div class="container">
      <h1 class="text-center mt-5">No hay promociones disponibles 😱😱</h1>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout.vue";
export default {
  components: {
    Layout,
  },
};
</script>

<style>
</style>