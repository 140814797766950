<template>
  <Layout>
    <div class="container mobile-m mt-5 mb-5">
      <div class="row">
        <div class="col-md-12">
          <h1 class="Reservas">{{traducciones.find((item)=>item.item == 'Reservas').value}}</h1>
          <p v-html="traducciones.find((item)=>item.item == 'ReservasTxt').value" id="ReservasTxt">
           
          </p>
        </div>
        <div class="col-md-5 my-3 my-sm-0">
          <Datepicker
            v-model="date"
            :minDate="new Date()"
            inline
            hideOffsetDates
            minutesIncrement="10"
            noHoursOverlay
            noMinutesOverlay
            :autoPosition="false"
            :startTime="startTime"
            locale="es"
            :selectText="`${traducciones.find((item)=>item.item == 'seleccionar').value}`"
            :time-picker-component="timePicker"
          />
        </div>
        <div class="col-md-7 my-5 my-sm-0">
          <form class="mb-5 p-3 p-sm-5 shadow bg-form" @submit="onSubmit">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="inputNombre" class="form-label">{{traducciones.find((item)=>item.item == 'Nombre').value}}</label>
                <input type="text" class="form-control" name="inputNombre" v-model="inputNombre" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputPersonas" class="form-label"
                  >{{traducciones.find((item)=>item.item == 'Numero de personas').value}}</label
                >
                <select id="numerodepersonas" name="numerodepersonas"  v-model="numerodepersonas" class="form-select" aria-label="Default select example">
                  <option id="selecciona" selected>{{traducciones.find((item)=>item.item == 'Seleccionar numero de personas').value}}</option>
                  <option id="una" value="1">1 {{traducciones.find((item)=>item.item == 'Personas').value}}</option>
                  <option id="dos" value="2">2 {{traducciones.find((item)=>item.item == 'Personas').value}}</option>
                  <option id="tres" value="3">3 {{traducciones.find((item)=>item.item == 'Personas').value}}</option>
                  <option id="cuatro" value="4">4 {{traducciones.find((item)=>item.item == 'Personas').value}}</option>
                  <option id="cinco" value="5">5 {{traducciones.find((item)=>item.item == 'Personas').value}}</option>
                  <option id="seis" value="6">6 {{traducciones.find((item)=>item.item == 'Personas').value}}</option>
                </select>
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputTelefono" class="form-label">{{traducciones.find((item)=>item.item == 'Telefono').value}}</label>
                <input type="number" class="form-control" name="inputTelefono" v-model="inputTelefono" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputEmail" class="form-label">{{traducciones.find((item)=>item.item == 'Email').value}}</label>
                <input type="email" class="form-control" name="inputEmail" v-model="inputEmail" />
              </div>
              <div class="col-12 mt-2 mb-3">
                <label for="Peticion" class="form-label"
                  >{{traducciones.find((item)=>item.item == 'Peticion').value}}
                </label>
                <textarea
                  class="form-control"
                  name="Peticion"
                  v-model="Peticion"
                  rows="3"
                ></textarea>
              </div>
              <div class="col-12">
                <button id="enviarcomentario" type="submit" class="btn btn-verde w-100">
                  {{traducciones.find((item)=>item.item == 'Enviar').value}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { API_URL } from "../utils/constants";
import Layout from "@/layout/Layout.vue";
import { useStore } from "vuex";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { computed, ref } from "@vue/reactivity";
import { defineAsyncComponent, onMounted } from "@vue/runtime-core";

export default {
  components: {
    Layout,
    Datepicker,
  },
methods:{
   onSubmit: async function (e) {
              e.preventDefault();
var sucursal = JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.id;
        try {
        let js = await fetch(API_URL+'/enviarReserva?sucursal='+sucursal+'&nombre='+this.inputNombre+'&movil='+this.inputTelefono+'&email='+this.inputEmail+'&fecha='+this.date+'&personas='+this.numerodepersonas+'&comentario='+this.Peticion);
        const res = await js.json()
        if (res.success)
        this.$swal({ position: "center",
                    icon: "success",
                    title: "La reserva se envió con éxito",
                    showConfirmButton: false,
                    timer: 2000,});
            
     
         else
        this.$swal({ position: "center",
                    icon: "error",
                    title: "Error al enviar reserva",
                    showConfirmButton: false,
                    timer: 2000,});
            
         }

        catch (rejectedValue) {
         this.$swal({ position: "center",
                    icon: "error",
                    title: "Error al enviar reserva",
                    showConfirmButton: false,
                    timer: 2000,});
        }
  } 
},
  setup() {
    const date = ref(new Date());
    const startTime = ref({ hours: 0, minutes: 0 });
    const store = useStore();
    // Lazy load the component we want to pass
    const TimePicker = defineAsyncComponent(() =>
      import("../components/horarios/Horario.vue")
    );

    // Return from computed as it is imported
    const timePicker = computed(() => TimePicker);

    onMounted(() => {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      date.value = [startDate, endDate];
    });
const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    return {
      traducciones,
      date,
      startTime,
      timePicker,
    };
  },
};
const $ = require('jquery');
window.$ = $;
$(function() {

});
</script>

<style lang="scss" scoped>
.bg-form {
  padding: 25px;
  background: #f3f6f9;
  border-radius: 8px;
  border: 1px solid #ebeff3;
}

.dp__main {
  width: 100%;
  z-index: 0;
  display: block;
}

div.container.mobile-m.mt-5.mb-5
  > div
  > div.col-md-5.my-3.my-sm-0
  > div
  > div.dp__menu.dp__relative.dp__theme_light
  > div.dp__action_row
  > div.dp__action_buttons
  > span {
  width: 100% !important;
}
</style>
