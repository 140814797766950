<template>
  <div class="opciones">
    <h4 class="mb-3">Opciones</h4>

    <div class="contenedor-opciones">
      <div class="form-check">
        <input
          @input="agregarValores()"
          class="form-check-input"
          type="checkbox"
          id="gridCheck1"
          :value="{ nombre: 'Producto 1', precio: 2.5 }"
          v-model="productos"
        />
        <label class="form-check-label" for="gridCheck1">
          Ingrediente 1 ( 2.50€ )</label
        >
      </div>
      <div class="form-check">
        <input
          @input="agregarValores()"
          class="form-check-input"
          type="checkbox"
          id="gridCheck2"
          :value="{ nombre: 'Producto 2', precio: 2.5 }"
          v-model="productos"
        />
        <label class="form-check-label" for="gridCheck2">
          Ingrediente 2 ( 2.50€ )</label
        >
      </div>
      <div class="form-check">
        <input
          @input="agregarValores()"
          class="form-check-input"
          type="checkbox"
          id="gridCheck3"
          :value="{ nombre: 'Producto 3', precio: 1.5 }"
          v-model="productos"
        />
        <label class="form-check-label" for="gridCheck3">
          Ingrediente 3 ( 1.50€ )
        </label>
      </div>
      <div class="form-check">
        <input
          @input="agregarValores()"
          class="form-check-input"
          type="checkbox"
          id="gridCheck4"
          :value="{ nombre: 'Producto 4', precio: 6.5 }"
          v-model="productos"
        />
        <label class="form-check-label" for="gridCheck4">
          Ingrediente 4 ( 6.50€ )
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "Opciones",
  props: ["opciones"],

  setup() {
    const productos = ref([]);
    const store = useStore();

    const agregarValores = () => {
      store.dispatch("productos/setOpcionesExtras", productos);
    };

    return { productos, agregarValores };
  },
};
</script>

<style lang="scss" scoped>
.opciones {
  margin-bottom: 30px;
}
.contenedor-opciones {
  display: grid;
  gap: 5px 10px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
}
</style>