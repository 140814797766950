<template>
  <div id="notificaciones" :class="[mostrarNotificaciones ? 'open' : '']">
    <div class="notificacion-header">
      <div>
        <h2>
          Notificaciones - <span>{{ notificaciones.length }}</span>
        </h2>
      </div>
      <div @click="ocultarNotificaciones()" class="cerrar-notificacion">
        <i class="bi bi-x"></i>
      </div>
    </div>

    <div
      class="notificacion-item"
      v-for="notificacion in notificaciones"
      :key="notificacion.id"
    >
      <img
        src="https://images.unsplash.com/photo-1441985969846-3e7c90531139?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80"
        alt="img"
      />
      <div class="text">
        <h4>{{ notificacion.titulo }}</h4>
        <p>{{ notificacion.descripcion }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  setup() {
    const store = useStore();
    const mostrarNotificaciones = computed(
      () => store.state.menu.mostrarNotificaciones
    );

    const notificaciones = computed(
      () => store.state.notificaciones.notificaciones
    );

    const ocultarNotificaciones = () => {
      store.commit("menu/mostrarNotificaciones", false);
    };

    return {
      notificaciones,
      mostrarNotificaciones,
      ocultarNotificaciones,
    };
  },
};
</script>

<style lang="scss" scoped>
#notificaciones {
  width: 300px;
  position: absolute;
  top: 65px;
  right: 10px;
  border-radius: 5px 0px 5px 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 99;
  display: none;

  &.open {
    display: block;
  }
}

// h2 span {
//   color: #f00;
// }

.notificacion-header {
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 16px;
    font-weight: bold;
    color: #283646;
    margin: 0;

    span {
      color: #46b975;
    }
  }
}

.notificacion-item {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 6px 9px;
  margin-bottom: 0px;
  cursor: pointer;
}

.notificacion-item:hover {
  background-color: #eee;
}

.notificacion-item img {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 9px;
  border-radius: 50%;
  margin-top: 2px;
}

.notificacion-item .text h4 {
  color: #283646;
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 4px;
}

.notificacion-item .text p {
  color: #606061;
  font-size: 12px;
}

.cerrar-notificacion {
  font-size: 22px;
  cursor: pointer;
}

.notificacion-cerrar {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>