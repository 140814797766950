<template>
  <div>
    <h1>Página no encontrada</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#noFound {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>