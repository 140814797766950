<template>
  <router-view></router-view>
  <Layout>
    <div v-if="productos.length > 0" class="container-xl mobile-m">
      <div class="contenedor-productos"
:class="[modoMosaico === 'mosaico' ? 'productos-mosaico' : '']"       
        >
        <ListaDeseo
          v-for="producto in productos"
          :key="producto.id"
          :props="producto"
        />
      </div>
    </div>

    <div class="no-disponible text-center" v-else>
      <h3 class="mt-5">{{traducciones.find((item)=>item.item == 'NoHayProductos').value}}</h3>
      <router-link :to="{ name: 'Home' }" class="mt-3"
        >{{traducciones.find((item)=>item.item == 'VolverMenu').value}}</router-link>
    </div>
  </Layout>
</template>

<script>
import { watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onBeforeMount, computed } from "@vue/runtime-core";

import ListaDeseo from "@/components/producto/ListaDeseo.vue";
import Layout from "@/layout/Layout.vue";
// import Loading from "@/components/loading/Loading.vue";

export default {
  components: {
    ListaDeseo,
    Layout,
  },

  setup() {
    const route = useRoute();
    const store = useStore();

const modoMosaico = computed(() => store.state.filtros.vista);
const restaurante = computed(() => store.state.restaurante.restaurante);
const productos = computed(() => store.state.productos.listaDeseo.filter((producto) => producto.sucursal == store.state.restaurante.restaurante.id));
    const loading = computed(() => store.state.productos.loading);

    watch(
      () => route.params.id,
      (value, prevValue) => {
        console.log(value, prevValue);
        store.dispatch("productos/fetchDestacados", value);
      }
    );

    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    return { modoMosaico, productos, loading, restaurante, traducciones };
  },
};
</script>

<style>
</style>
