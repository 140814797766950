<template>
  <Layout>
    <div class="container">
      <div class="col-md-12">
        <h2>Términos y Condiciones</h2>
        <hr class="half-margins" />
        <!-- separator -->

        <h3>DATOS IDENTIFICATIVOS</h3>
        <p>
          De conformidad con la Ley 34/2002, de 11 de julio, de Servicios de la
          Sociedad de la Información y de Comercio Electrónico (LSSICE) y de la
          Ley orgánica 15/1999, de 13 de diciembre, de Protección de Datos
          Personales (LOPD), se informa a los usuarios de que www.cartamovil.com
          es un dominio de Internet titularidad de la empresa indicada a
          continuación (en adelante el titular), siendo ésta la titular de los
          contenidos publicados a través del portal y la responsable del
          tratamiento de los datos recabados a través de la misma.
        </p>
        TITULAR DEL DOMINIO: CARTA FOOD S.L (Carta Food)<br />
        Nº FICHERO PROTECION DATOS: 2081370119 <br />
        REGISTRO: Sociedad inscrita en el Registro Mercantil de Mallorca, al
        Tomo: 2562 Libro: 0 Folio: 130 Sección: PM hoja: 73338 / Inscripción: 1º
        <br />
        CIF: B01598572<br />
        Domicilio: C/ METGE JOSEP DARDER, 37 OFICINA 2 – Palma de Mallorca – 07008 Baleares (España)<br />
        Email: info@cartafood.es <br /><br /><br />
        <p>
          Así mismo, actúan como encargados del tratamiento la empresa DIGITAL
          DREAMS S.L (CartaMóvil), con CIF. B57843062, propietaria de la
          plataforma y programación del portal,que proporciona el alojamiento
          del portal en servidores seguros, aplicando las medidas y
          procedimientos establecidos en la normativa vigente de aplicación en
          materia de protección de datos.
        </p>
        <hr class="half-margins" />
        <!-- separator -->

        <h3>CONDICIONES GENERALES DE ACCESO</h3>
        <p>
          Mediante el presente aviso legal se informa a los usuarios de las
          condiciones generales, para el acceso y consulta de los servicios que
          ofrece el portal. El titular se reserva el derecho a modificarlas sin
          previo aviso.
        </p>
        <p>
          El acceso y utilización de dichos servicios, por parte de los
          visitantes o usuarios, supone la aceptación plena y sin reservas de
          dichas condiciones generales.
        </p>
        <p>
          Se hace constar que este sitio Web no está especialmente dirigido a
          menores de edad.
        </p>

        <hr class="half-margins" />
        <!-- separator -->

        <h3>POLÍTICA DE ENLACES</h3>
        <p>
          El Portal pone a disposición de los Usuarios dispositivos técnicos de
          enlace, que permiten a los Usuarios acceder a sitios y páginas Web
          pertenecientes o gestionados por terceros. La instalación de estos
          enlaces, en el Portal tiene por objeto mejorar el contenido del web.
        </p>
        <p>
          El establecimiento del enlace no implica la existencia de relación
          alguna entre el titular y el propietario de la página Web enlazada,
          como tampoco la aprobación por parte del titular de sus contenidos y/o
          servicios, siendo su propietario el único responsable de los mismos
        </p>
        <p>
          El Usuario, por tanto, debe extremar la prudencia en la valoración y
          utilización de la información, contenidos y servicios existentes en
          los portales enlazados.
        </p>
        <p>
          Por todo ello, el titular no asume responsabilidad alguna sobre la
          información y servicios que puedan ofrecerse a través de estas páginas
          enlazadas.
        </p>

        <hr class="half-margins" />
        <!-- separator -->

        <h3>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
        <p>
          La totalidad de este sitio Web, incluyendo todos sus elementos, en
          especial software, textos, imágenes animadas o no, contenidos, marcas,
          logotipos son propiedad privada y se encuentran protegidas por las
          Leyes sobre Propiedad Intelectual e Industrial.
        </p>
        <p>
          En ningún concepto, el acceso al portal implica una renuncia o cesión
          de tales derechos, ni confiere derecho alguno de utilización,
          reproducción, distribución, modificación o explotación, sin disponer
          del previo consentimiento por escrito del titular de la misma.
        </p>
        <p>
          El usuario no puede colocar un hipervínculo con destino al portal sin
          autorización expresa y escrita del titular.
        </p>

        <hr class="half-margins" />
        <!-- separator -->

        <h3>LIMITACIÓN DE RESPONSABILIDADES</h3>
        <p>
          El titular declina cualquier responsabilidad por los eventuales daños
          y perjuicios que puedan ocasionarse al usuario, a causa de:
        </p>
        <ul>
          <li>
            La existencia de errores en el acceso al Portal, en su contenido o
            la falta de actualización del mismo
          </li>
          <li>
            La presencia de virus, o de otros elementos en la Web que puedan
            producir alteraciones en el sistema informático del usuario
          </li>
          <li>
            La falta de disponibilidad y/o continuidad del portal o de los
            servicios que se ofrecen en el mismo, que las transmisiones de
            información a través de Internet no sean totalmente seguras.
          </li>
          <li>
            Contingencias en la transmisión de información, como pérdida de
            datos o accesos no autorizados.
          </li>
          <li>Seguridad</li>
          <li>
            El titular ha adoptado las medidas físicas, técnicas y de gestión
            necesarias para mantener el nivel de seguridad requerido, según la
            naturaleza de los datos personales tratados y las circunstancias del
            tratamiento, con el objeto de evitar, en la medida de lo posible y
            siempre según el estado de la técnica, su alteración, pérdida,
            tratamiento o acceso no autorizado.
          </li>
        </ul>

        <hr class="half-margins" />
        <!-- separator -->
        <h3>USO DE LAS COOKIES</h3>
        <p>
          Se informa que algunas de las páginas del portal pueden instalar
          cookies de forma automática en su navegador. Si lo desea, puede
          configurar su navegador de forma que le avise de las solicitudes de
          colocación de cookies o para que las rechace por completo (puede
          acceder aquí para más información http://www.agpd.es o
          http://www.mityc.es/dgdsi/lssi).
        </p>

        <hr class="half-margins" />
        <!-- separator -->
        <h3>POLÍTICA DE PRIVACIDAD</h3>
        <p>
          El titular trata los datos que el usuario facilita a través de los
          formularios que se publican en el portal, siendo los campos marcados
          con asterisco obligatorios para poder atender su solicitud. El resto
          de datos que se solicitan con carácter no obligatorio, serán
          utilizados para mejorar los servicios propuestos.
        </p>
        <p>
          Con carácter general, los datos son tratados de forma confidencial e
          incluidos en un fichero titularidad del titular, con la finalidad de
          poder ofrecer un mejor servicio personalizado. Los datos no son
          cedidos a terceros sin disponer del previo consentimiento del usuario.
        </p>
        <p>
          El usuario puede ejercitar los derechos de acceso, rectificación o
          cancelación y oposición, enviando un escrito ordinario o bien mediante
          correo electrónico al titular.
        </p>
        <hr class="half-margins" />
        <!-- separator -->
        <h3>LEY APLICABLE. JURISDICCIÓN COMPETENTE</h3>
        <p>
          Las condiciones generales expuestas en este documento se rigen por la
          legislación española y se someten a la jurisdicción de los tribunales
          de Palma de Mallorca,Los gastos correrán a cargo de la parte
          interesada en su elevación a publico .
        </p>

        <hr class="half-margins" />
        <!-- separator -->
        <h3>POLÍTICA DE DEVOLUCIÓN</h3>
        <p>
          Ofrecemos una “Política de devolución y cambio de Productos”, para
          todos los productos de la marca CartaMóvil cuando los artículos sean
          devueltos en condiciones que puedan volver a ser vendidos y en su
          embalaje original dentro de los 7 días hábiles siguientes a la fecha
          de su recepción. Se entenderá por días hábiles, todos los días del año
          a excepción de sábados, domingos y festivos. Conforme a nuestra
          política de devoluciones, durante los siete días siguientes a la
          recepción de los productos, podrá devolver o cambiar los Productos
          adquiridos. Nuestra política de devoluciones no afecta a sus derechos
          legales de acuerdo con la legislación española. Sin embargo, nuestro,
          “Política de devolución y cambio de productos”, no es aplicable a
          ningún Producto de Marca Compartida, ya que estos se realizan a
          medida. Estos productos sólo podrán ser reembolsados o cambiados de
          conformidad con la normativa legal aplicable en cada momento. El
          procedimiento y condiciones aplicables a la devolución de cualquier
          producto, de acuerdo con la cláusulas anteriores es el siguiente: (A)
          Por favor, póngase en contacto con nuestra línea de teléfono de
          Servicios de Atención al Cliente en el +34 871 100 171 o por e-mail a
          info@cartamovil.com. Es posible que desee guardar una copia de la
          notificación del correo electrónico que envíe para su propio registro.
          Nosotros le proporcionaremos un Número de Confirmación Devoluciones y
          le confirmaremos la fecha en la que procederemos a recoger los
          productos. (B) Usted deberá entregarnos todos aquellos productos que
          desee cambiar o devolver. (C) Es importante que los artículos objeto
          de devolución, se encuentren en el mejor estado posible. Si usted ha
          devuelto productos de la marca CartaMóvil de conformidad con la,
          “Política de devolución y cambio de productos”, recibirá el reembolso
          completo del precio que pagó por los mismos (excluyendo cualquier
          gasto de entrega / gastos de envío aplicables). Los gastos de envío
          y/o entrega correrán a su cargo. Si los Productos son devueltos por
          estar defectuosos o equivocados, le devolveremos la totalidad del
          precio de los mismos, así como cualquier gasto de entrega y/o envío.
          Trataremos de efectuar el reembolso del precio a la mayor celeridad
          posible y en general con el objetivo de hacerlo dentro de los 30 días
          siguientes a la recepción de los productos devueltos, mediante
          reintegro del importe en la tarjeta de crédito o débito que utilizara
          para pagar.
        </p>
        <hr class="half-margins" />
        <!-- separator -->
        <h3>CONDICIONES DE PAGO</h3>
        <p>
          El pago de los pedidos a través de la Web sólo podrá realizarse
          mediante una tarjeta de débito o crédito. Por favor, tenga en cuenta
          que las transacciones de cobro pueden sufrir demoras o retrasos y, en
          ocasiones puede ocurrir que los pagos tarden hasta sesenta (60) días
          de ser deducidos de su cuenta bancaria o cargados en su tarjeta de
          crédito . El pago de los productos y todos los gastos de envío
          aplicables se realiza por adelantado. Realizaremos el cargo en su
          tarjeta de débito o tarjeta de crédito en el momento de realizar su
          pedido.
        </p>
        <hr class="half-margins" />
        <!-- separator -->
        <h3>EVENTOS FUERA DE NUESTRO CONTROL</h3>
        <p>
          19.1 No nos hacemos responsables de ningún fallo en la ejecución o
          retraso en la ejecución de ninguna de nuestras obligaciones en virtud
          del Contrato que sea debida a hechos que se encuentren fuera de
          nuestro control (“Fuerza Mayor”). 19.2 Por Fuerza Mayor se entenderá
          cualquier acto, evento, omisión o accidente que se encuentre fuera de
          nuestro control e incluye en particular (sin limitación) los
          siguientes: (a) huelgas, paros o cualquier otra acción industrial; (b)
          revueltas civiles, motines, invasiones, ataques terroristas, guerras
          (declaradas o no), amenazas o preparaciones de guerra. (c) fuego,
          explosión, tormenta, inundación, terremoto, hundimiento, epidemias y
          cualquier otro desastre natural. (d) imposibilidad de utilizar el
          ferrocarril, barcos, aviones, transportes a motor o cualquier otro
          tipo de transporte público o privado. (e) imposibilidad de uso de las
          redes de telecomunicaciones públicas o privadas y (f) los actos,
          decretos, legislación, regulación o restricciones de cualquier
          gobierno. 19.3 Nuestras obligaciones quedarán suspendidas durante el
          tiempo que dure el supuesto de Fuerza Mayor y dispondremos de una
          ampliación del plazo para el cumplimiento de las mismas por la
          duración de dicho período.Trataremos por todos los medios de poner fin
          al evento de fuerza mayor o encontrar una solución mediante la cual
          podamos llevar a cabo nuestras obligaciones conforme a los presentes
          Términos de sitio Web a pesar de la Causa de Fuerza Mayor.
        </p>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/layout/Layout.vue";
export default {
  components: {
    Layout,
  },
};
</script>

<style>
</style>
