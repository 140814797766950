export const idiomas = {
  namespaced: true,
  state: {},
  actions: {
    async fetchOpciones({ commit, rootState }) {
      try {
        let opciones = { idiomas: []};
        for(var i = 0; i < rootState.restaurante.restaurante.idiomas.length; i++) {
            var idioma = rootState.restaurante.restaurante.idiomas[i];
            let titulo = idioma.nombre;
            idioma.nombre = idioma.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
            opciones.idiomas.push({nombre: titulo, imagen: idioma.nombre + '.svg', locale: idioma.id});
            }
        let locale = {
          ...opciones,
          locale: rootState.restaurante.restaurante.locale_default
        }

        commit("agregarOpciones", locale);
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
      }
    },
  },
    methods : {
     update(){
      this.$forceUpdate();
    },
    },
  mutations: {
   
    agregarOpciones(state, payload) {
      state.options = payload;
    },
    cambiarIdioma(state, payload) {
      state.options.locale = payload
    }
  },
  getters: {
    OrdenarPorIdioma: (state, _, rootState) => {
      if (state.options) {
        const find = state.options.idiomas.filter(
          producto => producto.locale === rootState.idiomas.options.locale
        );
        
        return find;
      }
    },
  }
};
